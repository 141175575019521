.node-type-job {
	.view-display-id-member {
		margin-bottom: 20px;
	}
	
	.res-details > div:nth-last-child(1),
	.res-details > div:nth-last-child(2) {
	  margin-bottom: 30px;
	}
	
	.card-member {
		font-weight:400;
	}
}