// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary							: #252422; /* raisin_black */ 
$secondary						: #403C38; /* black_olive */ 



//// /* MAIN PALETTE */ //
$weldon_blue					: #7596AE; /* weldon_blue */
$mango_tango					: #FF8838; /* mango_tango */



//// /* SEC PALETTE */ //
$medium_vermilion 		: #DF573B; /* medium_vermilion */

$isabelline						: #F0F0F0; /* isabelline */
$platinum							: #E6E6E6; /* platinum */
$black_olive					: #403C38; /* black_olive */
$raisin_black					: #252422; /* raisin_black */

$platinum_blue				: #D9E2E8; /* platinum_blue */
$sea_blue							: #0E6794; /* sea_blue */
$columbia_blue				: #CFE1EA; /* columbia_blue = sea_blue at 20% */ 
$independence					: #40525F; /* independence */
$glitter							: #E6F0F4; /* glitter = sea_blue at 20% */ 
$white_smoke					: #F5F5F5; /* white_smoke */
$trolley_grey 				: #807E7A;
$baby_powder					: #FDFDFD; /* baby_powder */
$lavender_gray				: #C8CBCE; /* lavander_grey */
$lochinvar            : #2C988B; /* lochinvar */
$pine_green           : #00776D; /* pine_green */
$persian_green        : #00998C;

/* ---- REPLACE PEARL AQUA! ---*/
$pearl_aqua						: #81D2C7; /* pearl_aqua */


// /* LINKS */ //
$action-default				: $sea_blue;
$action-hover					: $mango_tango;
$action-active			 	: $mango_tango; 
$action-active-hover	: $mango_tango; 


// /* SHADOWS */ //
$box-shadow : 0 0 6px 0 rgba(0,0,0,0.1); //0 2px 4px 0 rgba(0,0,0,0.12)


// /* BORDERS */ //
$primary-border-color						: rgba($independence,0.1);
$primary-border-radius					: 0;
$primary-border									: 1px solid $primary-border-color;

$secondary-border-color					: rgba($weldon_blue, 0.4);
$secondary-border-color-radius 	: 0;
$secondary-border								: 1px solid $secondary-border-color;

$tertiary-border-color					: $white_smoke;
$tertiary-border-radius					: 0;
$tertiary-border								: 1px solid $tertiary-border-color;

$base-border										: 1px solid rgba($independence,0.1);
$base-border-radius							: 0;


// /* TABLES */ //
$table-bg-color 						 		: white;
$base-accent-color 							: $mango_tango; /*#477DCA;*/
$table-border-color			 				: $primary-border-color;
$table-cell-border							: 1px solid $table-border-color;
$table-border						 				: 1px solid $table-border-color;
$table-header-bg-color					: $platinum_blue;
$table-header-border						: 1px solid darken($table-border-color, 10%);
$table-header-color							: $independence;
$table-header-hover-bg-color		: lighten($platinum_blue, 3%);
$table-hover-bg-color		 				: lighten($platinum, 9%);
$table-stripe-bg								: darken($table-bg-color, 4);
$table-stripe-bg-hover					: darken($table-stripe-bg, 5);
$table-padding									: .75em 1em;
$table-caption-bg-color	 				: trolley_grey;
$table-caption-text-color 			: white;
$table-caption-border		 				: 1px solid white;

// /* FORMS	*/ //
$fieldset-border-color 	: $secondary;
$form-box-shadow			 	: none; /*$box-shadow;*/
$form-box-shadow-focus 	: 0 0 5px 0 $weldon_blue;
$form-border-color		 	: $secondary-border-color;
$form-border					 	: 1px solid $secondary-border-color;
$form-border-radius			: 40px;

$button-bg							: $mango_tango !default;
$button-hover-bg			 	: $medium_vermilion !default;
$button-text					 	: white !default;
$button-border				 	: none !default;
$button-border-radius		: 40px !default;
$button-hover-text		 	: white !default;

$button-transition		 	: background-color 300ms linear 0s;


// /* MESSAGES */ //
$alert-color					 	: lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color					 	: lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color						: lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color				 	: lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius 	: 3px;
$message-border-style		: 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : $isabelline;
$base-font-color			 : $primary;
$primary-border-radius : 0;
$flex-box-background	 : white !default; /*$pale_grey*/