.alnap-topics-list {
	display: block;
  width: 100%;
	
	iframe {
		width: 100%;
	}
}

.active-users-block {
	width: 100%;
	
	.discourse-active-users {
		li {
			width: 100%;
			margin-bottom: 5px;
			
			& > span {
				display: flex;
		    flex-flow: row;
		    flex-wrap: wrap;
		    justify-content: space-between;
				
				.duser_name {
					// width: calc(100% - 100px);
					width: fit-content;
					min-width: 160px;
				}
				.duser_posts {
					width: 120px;
					text-align: left;
					font-size: 0.875em;
					color: #807E7A;
				}
			}
		}
	}
}


// REMOVE COPS BUTTON - ALSO CHANGE discourse.scss and compress scss and js
.alnap:not(.role-administrator):not(.role-editor):not(.role-discourse-melog-users)  {
	#site-header .wrapper-region-header .block-menu a[href="/discourse"] {
		display: none;
	}
}

// >> BACKUP DISCOURSE - DON'T DELETE <<
// >> KEEP THE CSS STRUCTURE! <<

// DISCOURSE styles inside discourse
// https://discourse.mrmdev2.co.uk/admin/customize/themes/2/common/scss/edit

/*
@import url('https://fonts.googleapis.com/css?family=Oswald:400,500,700|Roboto:400,500,700');

html, body {
    color: #252422;
    font-family: "Roboto","Open Sans","Segoe UI",Tahoma,sans-serif;
    font-size: 16px;
    line-height: 1.5;
}
h1, h2, h3, h4, h5, h6, .title {
    font-family: "Oswald",Impact,"Franklin Gothic Bold",sans-serif;
    font-weight: 500;
}
.topic-list-main-link,
.topic-list .main-link,
.latest-topic-list-item .main-link {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.33333;
}
.topic-list-main-link a.title,
.topic-list .main-link a.title,
.latest-topic-list-item .main-link a.title {
    color: var(--tertiary);
}
a:hover,
.topic-list-main-link a.title:hover,
.topic-list .main-link a.title:hover,
.latest-topic-list-item .main-link a.title:hover{
    color: #FF8838;
}
.btn:hover,
.btn.btn-hover,
.discourse-no-touch .btn:hover,
.discourse-no-touch .btn.btn-hover {
    background-color: #FF8838;
    color: white;
}
table.topic-list {
    border-collapse: separate;
}
table.topic-list th {
    background-color: #40525F;
    color: white;
}
table.topic-list th.sortable:hover {
    background-color: #FF8838;
    color: white;
}
table.topic-list th button .svg-icon {
    color: white;
}
table.topic-list th button:hover .svg-icon {
    color: #FF8838 !important;
}
tbody {
    tr {
        
    }
}
.topic-list tr {
    background-color: transparent;
}
.topic-list td,
.topic-list td.posts {
    padding: 20px 5px;
    background-color: white;
    border-top: 20px solid #F0F0F0;
}
.topic-list th:first-of-type,
.topic-list td:first-of-type {
    padding-left: 30px;
}
.topic-list th:last-of-type,
.topic-list td:last-of-type {
    padding-right: 30px;
}
.topic-list td:last-of-type {
    border-radius: 0 0 20px;
}
.topic-list td .link-bottom-line .badge-wrapper {
    margin-top: 10px;
}
#reply-control,
.d-editor-textarea-wrapper,
.reviewable-filters {
    background-color: white;
}
.posts-wrapper {
    background-color: white;
    padding: 20px;
}
*/



// IFRAME styles inside discourse + ALNAP.ORG on discourse views
// https://discourse.mrmdev2.co.uk/admin/customize/themes/2/common/embedded_scss/edit

// @import url('https://fonts.googleapis.com/css?family=Oswald:400,500,700|Roboto:400,500,700'); // ENABLE ON DISCOURSE

.discourse-topics-view .discourse-alnap {
    width: 100% !important;
}
.discourse-alnap {
	//background-color: #F0F0F0; // ENABLE ON DISCOURSE
}
.discourse-alnap .topics-list {
    display: flex;
    flex-wrap: wrap;
}
.discourse-alnap .topics-list .topic-list-item {
    display: block;
    width: 100%;
}

@media (max-width: 719px) and (min-width: 486px) {
    .discourse-alnap .topics-list .topic-list-item:not(.views-row) {
        margin-right: 2.35765%;
        width: 48.82117%;
    }
    .discourse-alnap .topics-list .topic-list-item:not(.views-row):nth-child(2n) {
        margin-right: 0;
    }
}
@media screen and (min-width: 45em) {
    .discourse-alnap .topics-list .topic-list-item:not(.views-row) {
        margin-right: 2.35765%;
        width: 23.23176%;
    }
    .discourse-alnap .topics-list .topic-list-item:not(.views-row):nth-child(4n) {
        margin-right: 0;
    }
}

.discourse-alnap .topics-list .topic-list-item .main-link {
	padding: 0;
	border: none;
}
.discourse-alnap .topics-list .topic-list-item .topic-column-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}				
.discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .details-column {
	display:block;
  background-color: #ffffff;
  border: 0;
  border-radius: 0 0 20px 0;
  box-shadow: none;
  margin-bottom: 30px;
	padding: 20px 30px;
	font: 300 1rem/1.5 "Roboto","Open Sans","Segoe UI",Tahoma,sans-serif;
	color:#403C38;
	order: 2;
	width: auto;
}
aside .discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .details-column {
  margin-bottom: 30px;
}
aside .discourse-alnap .topics-list .topic-list-item:last-child .topic-column-wrapper .details-column {
	margin-bottom: 0;
}
.palette-alt-2 .discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .details-column,
aside .discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .details-column {
	background-color: #F0F0F0;
}
.discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .topic-column.details-column .topic-last-posted-at,
.discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .topic-column.details-column .topic-created-at,
.discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .topic-column.details-column .topic-stats,
.discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .topic-column.details-column .topic-author-avatar-timestamp {
    color:#403C38;
    margin-top: 5px;
    padding: 0;
}
.discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .topic-column.details-column .topic-last-posted-at {
    font-weight: 500;
    margin-bottom: 10px;
}
.discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .topic-column.details-column .topic-author-avatar-timestamp .topic-author-username {
		padding-left: 25px;
    background: url(https://www.alnap.org/sites/all/themes/alnap/images/discourse-user-icon.svg) transparent no-repeat 0 / 20px 20px;
    min-height: 20px;
    display: block;
}
.discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .topic-column.details-column .topic-author-avatar-timestamp img {
    border-radius: 50%;
		display: none;
}
.discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .topic-column.details-column .topic-created-at {
	display:block;
	padding:0;
	clear:both;
	margin-top: 10px;
	font-size: 0.875em;
	color: #807E7A;
}
.discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .topic-column.details-column .topic-stats {
    font-size: 0.875em;
    margin: 0;
	color: #807E7A;
}
.discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .topic-column.details-column a.raw-topic-link {
	display:block;
    border: 0;
	margin-bottom: 10px;
	font: 500 1.5rem/1.33333 "Oswald",Impact,"Franklin Gothic Bold",sans-serif;
	letter-spacing: 0.03rem;
	color:#0E6794;
}
.discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .topic-column.details-column a.raw-topic-link:hover {
	color:#FF8838;
}
.discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .topic-column.featured-image-column {
    order: 1;
}
.discourse-alnap .topics-list .topic-list-item .topic-column-wrapper .topic-column.featured-image-column .topic-featured-image img {
    max-width: 100%;
    max-height: fit-content;
}


// EVALMAPPER Header
body.me-cops {
  .content-title {
		background: linear-gradient(90deg, #00AD96 50%, #00998C 51%);
		padding: 0;
    .outer-wrapper {
			background: transparent $me_hub_banner 0% 50% no-repeat;
    	height: calc(100vw * 0.145);
			@media (min-width:1200px) {
				height: 180px;
			}
	    h1 {
	      display: none;
	    }
		}
  }
}