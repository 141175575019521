.node-type-organisation {
	
	.block-views h2 {
		
	}
	
	// MEMBER HERO
	section.content-hero {
		background-color: $isabelline;
		
		.body-expand {
			overflow:hidden;
			
			.card-membership {
				background-color:white;
				padding:20px 0 0 20px;
			}
			
			.org-details {
				background-color:white;
				padding:20px 20px 20px 30px;
				
				.card-country,
				.card-url,
				.card-constituency-type-1,
				.card-member-address {
					display: inline-block;
					width:48%;
					vertical-align: top;
				}
				
				.card-member-address {
					&:before {
					content:"Address";
					font-size: 1.5rem;
					font-weight: 500;
					line-height: 32px;
					margin: 10px 0;
					font-family: $title-font-family;	
					}
				}
			}

			.card-member-rep {
				display: inline-block;
				margin: 20px 0 0 20px;
				font-size:1.125em;

				.label {
					font-weight: bold;
					display: inline-block; 
				}
			}
		}
		
		.card-edit-node {
			display: inline-block;
    		margin: 22px 0 5px 0;
    		position: relative;
			float:right;
			
			.member-edit a {
				background-color:$action-default;
				color: white;
				padding: 5px 14px;
				border-radius: 20px;

				&:hover {
					background-color:$action-hover;
					color: white;
				}
			}
		}
	}
	
	a.card {
		background-color:white;

		h3 {
			color:$card-light-action-default;
		}
		&:hover h3 {
			color:$card-light-action-hover;
		}
	}
	
	.view-id-member {
		&.view-display-id-event,
		&.view-display-id-resources,
		&.view-display-id-blog_news {
			max-width: 1600px;
 			margin-left: auto;
  		margin-right: auto;
  		clear: both;
		}
	}
	
	//EVENT FILTER

	.view-id-member.view-display-id-event {
		overflow:hidden;

		.views-submit-button {
			display:none;
		}
		.views-exposed-form #edit-dates-wrapper {
			margin:0 0 15px 0;
			display:inline-block;
			width:100%;

			label { 
				display:inline-block;
				float:left;
				line-height: 2.22222222222222;
			}
			.views-widget-filter-dates {
				display:inline-block;
			}
		}
	}
}

.view-id-members {
	.body .view .view-filters {
		margin-bottom:30px;
	}
	.list {
		.card-logo {
			border-bottom: none;
		}
	}
	.card-logo {
		padding-bottom: 10px;
		border-bottom: 1px solid $platinum_blue;
	}
	.view-content {
	}
	.view-header h2 {
		position: relative;
    clear: both;
    overflow: hidden;
    margin: 0 0 76px;
	}
}

.view-members {
.view-content,
.view-header {
	@include clearfix;
	
}
}
