.soc-accelerators {
	h2 {
		background: $independence;
		color: white;	
		font: normal 1.25rem/1.35 $title-font-family;
		padding: $vert-space/2 $horz-space/4;
		margin: 0 0 1px;
		text-align: center;
		text-transform: uppercase;	
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		
		li {
			margin: 0 0 1px;
			
			a {
				background: $platinum_blue;
				display: block;
				height: 60px;
				text-indent: -9999px;
			}
		}
		
	}
}

@media (max-width:719px) {
	#sidebar-first .soc-accelerators ul li {
    width: 80px;
    margin: 0 1px 1px 0;
    float: left;
	}
}