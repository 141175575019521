.tabs {
  &.primary {
    @include clearfix;
    line-height: 1.5;
    padding: 0 $horz-space/2;
    margin: $vert-space 0 0;
    border-bottom: $tab-border-bottom;

    li {
      list-style: none;

      @include media($narrow) {
        display: inline-block;
      }

      a {
        background-color: $tab-inactive-bg-color;
        border-top: $tab-border;
        color: $tab-inactive-text-color;
        display: inline-block;
        font-weight: 400;
        padding: ($vert-space / 2) $gutter;
        text-decoration: none;

        @include media($narrow) {
          //@include inline-block;
					display:inline-block;
          border-top: 0;
        }

        &:hover {
          background: $tab-hover-bg-color;
          color: $tab-hover-text-color;
        }

        &:focus {
          outline: none;
        }

        &.active {
          border-bottom: 0;
					background: $tab-active-bg-color;
					color: $tab-active-text-color;

          @include media($narrow) {
            border: $tab-active-border;
            border-bottom-color: $tab-active-bg-color;
            margin-bottom: -1px;
          }
        }
      }

      .tab-content {
        display: none;
        padding: $vert-space $gutter;
        width: 100%;

        @include media($narrow) {
          border-top: $tab-border;
          float: left;
        }
      }
    }
  }
}
:not(.role-administrator):not(.role-editor) {
	&.page-user,
	&.page-user-forms {
		.tabs {
			display: none;
		}
		
	}
}
