blockquote {
	font: 500 1.75rem/1.35714285714286 $title-font-family;
	margin: $vert-space*2 0;
	padding: 0 10% 0 calc(10% + 24px);
	
	:first-child {
		&:before {
			content: '\201C';
			margin-left: -24px;
			vertical-align: middle;
		}
	}
	:last-child {
		&:after {
			content:'\201D';
			vertical-align: middle;
		}
	}
}