// USED ON RESEARCH TOPICS & PORTALS

.view-id-twitter_hashtag {
	@include palette-default-slick-controls;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
	
	.view-header {
		text-align: center;
		margin-top:40px;
		
		h2.card-title {
			background: $title-prefix no-repeat 0 100% / 34px 58px,
									$title-suffix no-repeat 100% 0 / 34px 58px;
			/*color:$primary;*/
			display: inline-block;
			font:$section-row-title-font;
			padding: 12px 25px;
		}
	}
	
	.card {
		background-color:white;
	}
	.slick-controls {
		display:block;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		@media(min-width:720px) {
			max-width:35%;
		}
		@media(min-width:960px) {
			max-width:25%;
		}
	}
}