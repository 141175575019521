main {
	background-color:$main-page-bg;

	#content { padding:0 $horz-space/2; }
}

.content-title {
	background-color:$main-page-bg;	
	padding:$vert-space $vert-space/2 $vert-space/2;
}
.postscript-first {
	background-color:$main-page-bg;

}

.front main {display: none}

.outer-wrapper {
	@include outer-container($site-max-width);
}

.block {
	position: relative;
}
hr {
	border:none;
	border-bottom:$primary-border;
	margin: 0 0 $vert-space;
}
p + hr,
ul + hr,
ol + hr,
address + hr,
pre + hr,
blockquote + hr {
	margin-top: $vert-space;
}

a {
	outline: none;
}

.caption {
	display: block;
	text-align: left;
	font-size: 0.875em;

	p {
		line-height: 1.42857142857143;
		margin: 5px 0 1em 3px;
		
		&:last-child {margin-bottom: 0;}
	}
}

.details-box {
	background: white;
	padding: 20px;
	-webkit-columns: auto 2;
  -moz-columns: auto 2;
  columns: auto 2;
  -webkit-column-gap: 2em;
  -moz-column-gap: 2em;
  column-gap: 2em;
	break-inside: avoid;
	font-size: 1.125rem;
	/*
	&:nth-child(n+1) {
		margin-left:-5px;
	}*/
	@media (max-width:719px){
		-webkit-columns: auto;
  		-moz-columns: auto;
  		columns: auto;
	}
}

.details-label {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 32px;
    margin: 0 0 10px;
    font-family: $title-font-family;
}

.view-related,
.view-tagged-sidebar {
	margin-top:20px;
}

button .ytp-large-play-button.ytp-button {
	background:$mango_tango;
}

.d-hide {
	display: none;
}

.global-evaluations {
	background: white;
	padding: 20px;
	display: none;
}

button.download-csv {
	float: right;
}