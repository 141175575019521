@media (min-width:$narrow) and (max-width:959px) {
	.view-curated-links.view-display-id-featured_first {
		@include clearfix;
		.view-content-main {
			@include span-columns(6);
		}
	}
}
@media (min-width:500px) and (max-width:719px) {
	.help-promo {
		.promo-icon,
		.promo-text{
			display: table-cell;
    	vertical-align: middle;
		}
		.promo-text{
			width:calc(100% - 190px);
			padding-left: 30px;
		}
		.view-counter {
			margin-top: $vert-space;
			text-align: center;
			.counter-figure {
				margin-right: 7px;
			}
			.counter-figure,
			.counter-type {
				color: $primary;
				display: inline-block;
				font-size: 3.75rem;
				line-height: 1;
				margin-bottom: $vert-space;
			}
		}
	}
}
@media (min-width:$narrow) and (max-width:1199px) {
		.feat-curated {
			@include span-columns(8);
		}
	.view-curated-links.view-display-id-featured_first {
		.view-content-main {
			@include span-columns(12);

		}
		.attachment .view-content-main {
			@include span-columns(12);
			.views-row {
				@include span-columns(6);
				.card {
					margin-bottom: 0;
				}
			}
		}
	}
	.help-promo {
		@include span-columns(4);
		margin-right: 0;
		float: right;
	}
}
@media (min-width:$wide) {
	.view-curated-links.view-display-id-featured_first {
		.view-content-main {
			@include span-columns(6);
			.card {
				margin-bottom: 0;
				height: 100%;
			}
			.views-row, article, .card {
				height: 100%;
			}
		}
		.attachment .view-content-main {
			@include span-columns(3);
			.views-row {
				height: calc(50% - 15px);
				 margin-bottom: $card-margin-bottom;
				
				
				&.views-row-last {
					margin-bottom: 0;
				}
			}
			//article{
				//height: auto;
			//}
		}
	}
	.help-promo {
		@include span-columns(3);
		margin-right: 0;
		float: right;
	}
}

.promo-icon {
	background: $logo-help 50% 50% / 160px auto no-repeat transparent;
	display: block;
	height: 160px;
	margin: 0 auto $vert-space;
	position: relative;
	text-indent: -9999px;
	width: 160px;
}
.promo-text {
	color: $secondary;
	font: 500 1.625rem/1.346153846153846 $title-font-family;
	margin-bottom: $vert-space*1.5;
}
.help-promo {
	background: $platinum_blue;
	box-sizing: border-box;
	padding: $vert-space*1.5 $horz-space*1.5;
	
	.counter-figure,
	.counter-type {
		color: $primary;
		display: block;
		font-family: $title-font-family;
		text-align: center;
	}
	.counter-figure {
		font-size: 3.75rem;
		font-weight: 500;
		line-height: 1;
	}
	.counter-type {
		font-size: 2.5rem;
		font-weight: 500;
		line-height: 40px;
		margin-bottom: $vert-space;
	}
	.view-filters {
		label {
			visibility: hidden;
			height: 0;
		}
		#edit-keywords {
			border-radius: 40px 0 0 40px;
			color: $secondary;
			height: 39px;
			font-size: 0.875rem;
			padding: 0 22px 0 15px;
		}
		
		.views-exposed-form .views-exposed-widget {
			padding: 0;
			
			&.views-widget-filter-keys {
			width: calc(100% - 22px);				
			}
		}
		.views-submit-button {
			margin-left: -33px;
			input[type="submit"] {
				border-radius:40px;
				font: bold 1.125rem/1.16666666666667 $base-font-family;
				height: 39px;
				margin: 5px 0 0;
				margin: 0;
				padding: 0 15px;
				text-transform: uppercase;
				width: 55px;
			}
		}
	}
}

