.postscript-first .section-row {
	&.palette-default,
	&.palette-highlight,
	&.palette-alt-1,
	&.palette-alt-2,
	&.palette-alt-3 {
		
		.promo {
			display:table;
			width:80%;
			margin:0 10%;
			@media (max-width:1320px) { //1199px
				width:100%;
				margin:0 1%;
			}
			
			@media (max-width:539px) {
				width:90%;
				margin:0 5%;
			}
			
			.card-image {
				display:table-cell;
				vertical-align:middle;
				
				@media (max-width:540px) {
					display:block;
				}
				
				.promo-image {
					margin-right: 20px;
					
					img {
						float:left;
						max-height: 200px;
					
						@media (max-width:539px) {
							float:none;
							max-height: none;
						}
					}
				}
			}

			.card-promo-description{
				margin-right: 20px;
			
				.promo-description {
					display:table-cell;
					vertical-align:middle;
					//padding-top: 15%;

						@media (max-width:540px) {
							display:block;
							padding-top:20px;
						}

					h2, h3, h4, h5 {
						color:white;
						text-align: left;
					}

					p {
						color:rgba(225, 225, 255, 0.6);
					}
				}
			}

			.card-link {
				display:table-cell;
				vertical-align:middle;
				
				@media (max-width:1100px) {
					display:block;
				}
				
				.promo-link {
					background-color: $action-hover;
					padding: 7px 20px;
					border-radius: 25px;
					display: inline-block;
					text-align:center;

					&:hover {
						background-color: $action-default;
					}

					a {
						color:white;
						background:none;
						text-transform:uppercase;
						font-weight:bold;
						font-size:1.125rem;
						
						@media (max-width:1199px) {
							font-size:0.875rem;
						}
					}
				}
			}
		}
	}
}