.node-type-research-topic {
	.view-res-topic-node {
		.card-title {
			text-align:center;
		
			h2 {
				font:$section-row-title-font;
				background: $title-prefix no-repeat 0 100% / 34px 58px,
										$title-suffix no-repeat 100% 0 / 34px 58px;
				display: inline-block;
				padding: 12px 25px;
			}
		}

		//RESEARCH LINKS
		&.view-display-id-links {
			overflow:hidden;
			
			.card {
				background: $icon-background repeat center center / 17px auto $platinum_blue;

				.card-text {
					text-align:center;

					.card-title h3 {
						text-align:center;
						color:$primary;
					}

					.card-link-et {
						background-color:$mango-tango;
						border-radius: 25px;
						display:inline-block;
						padding: 8px 20px;

						&:hover {
							background-color:$medium_vermilion;
						}

						a {
							text-transform:uppercase;
							font-weight: bold;
							font-size:1.125em;
							color:white;
						}
					}
				}
			}
		}

		//FEATURED CONTENT
		&.view-display-id-featured {
			a.card {
				background-color:white;
				
				h3 {
					color:$card-light-action-default;
				}
				&:hover h3 {
					color:$card-light-action-hover;
				}
			}
		}
		
		&.view-display-id-subtopic {
			.card-view {
				clear:both;
			}
			.body-expand {
				max-width: 1160px;
			}
			.body-expand.has-image {
				max-width: none;
			}
		}
	}
}

.node-type-event {
	.view-id-event_node .event-display-date {
			font-size:1.3em;
		}
	.view-display-id-location,
	.view-display-id-contact {
		background-color:white;
		padding:20px;
		margin-top:20px;

		.card-location {
			font-size:1em;
			font-weight:400;		
		}
	}
}

.alnap-label {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 8px;
    font-family: $title-font-family;
}
