.view-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}
	
	.views-row {
		padding: $vert-space/2 0;
	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space;
		
		.file-icon {
			float:left;
			margin:0 $horz-space 0 0;
			min-width:4rem;
			.file-extension {
				padding:$vert-space*0.3 $horz-space/4;
				text-transform:uppercase;
				font-size:0.75em;
        		line-height:1;
				text-align:center;
				font-weight:bold;
			}
			.file-size {
        		padding: 3px 5px;
				font-size:0.750em;			
				text-align:center;
				color:$secondary;
				border:$secondary-border;
				border-top:none;
				background-color: white;
			}
		}
		
		.file-name {
			overflow:hidden;
		}
	}


	// /* FILE TYPE COLOURS */
  
  .file-type-docx, .file-type-doc {
    .file-extension {
      background:#2d5994;
      color:white;
    }
  }
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      background:#207347;
      color:white;
    }
  }
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      background:#D04727;
      color:white;
    }
  }
  .file-type-pdf {
    .file-extension {
      background:#C11E07;
      color:white;
    }
  }
  .file-type-rtf, .file-type-txt {
    .file-extension {
      background:#023A6D;
      color:white;
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      background:#FAC74C;
      background:#D19B15;
      color:white;
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
    .file-extension {
      background:black;
      color:white;
    }
  }
} 
