$section-main-padding : $vert-space*1.5 0 !default;

body {
  main {
    padding: $section-main-padding;
    display: flex;
    flex-wrap: wrap;
  }

  &.no-sidebars {
    main {
      section#content {
        width: 100%;
      }
      
    }
  }

  &.one-sidebar.sidebar-first {
    main {
      .primary {
        width:100%;
      }
      .outer-wrapper {display:flex;flex-wrap:wrap;}
      aside {
        &#sidebar-first {
          width: 100%;
          margin: calc(#{$gutt} / 2);
					margin-top: 25px;
          
          @include media($narrow) {
            width: calc(100%/12 -  #{$gutt}/2);
          }
        }
      }

      section#content {
        width: 100%;
        margin: calc(#{$gutt} / 2);
				margin-top: 0;
        @include media($narrow) {
          width: calc(100%/12*11 -  #{$gutt}*2);
					margin-right: 0;
        }
      }
     } 
  }

  &.one-sidebar.sidebar-second {
    main {
      .primary {
        width:100%;
      }
      .outer-wrapper {display:flex;flex-wrap:wrap;} 
      aside {
        &#sidebar-second {
          width: 100%;
          margin: calc(#{$gutt} / 2);
					margin-top: 0;
          @include media($narrow) {
            width: calc(100%/3 -  #{$gutt});
          }
        }
      }

      section#content {
        width: 100%;
        margin: calc(100%-#{$gutt} / 2);
				margin-top: 0;
        @include media($narrow) {
          width: calc(100%/3*2 -  #{$gutt});
        }
      }
    }
  }

  &.two-sidebars {
		
		main {
      .primary {
        width:100%;
      }
      .outer-wrapper {display:flex;flex-wrap:wrap;}
      aside {
        &#sidebar-first {
          width: 100%;
          margin: calc(#{$gutt} / 2);
					margin-top: 25px;
					
          @media (max-width:$narrow) {
						order: 3;
					}
          @include media($narrow) {
            width: calc(100%/6 -  #{$gutt}/2);
          }
          @include media($normal) {
            width: calc(100%/12 -  #{$gutt}/2);
          }
        }

			  &#sidebar-second {
          width: 100%;
          margin: calc(#{$gutt} / 2);
					margin-top: 0;

          @media (max-width:$narrow) {
						order: 2;
					}
          @media (min-width:$narrow) and (max-width:959px) {
						margin-left: 16.666666666667%;
						width: calc(83.333333333333% -  #{$gutt}/2);
          }
          @include media($normal) {
            width: calc(100%/4 -  #{$gutt}/2);
          }
        }
			}

      section#content {
        width: 100%;
        margin: calc(#{$gutt} / 2);
				margin-top: 0;

        @media (max-width:$narrow) {
					order: 1;
				}

				@include media($narrow) {
          width: calc(83.333333333333% -  #{$gutt}*2);
					//margin: 0;
        }
        @include media($normal) {
          width: calc(100%/1.5 -  #{$gutt}*2);
        }
      }
    }
  }
}