#mc_embed_signup {
	box-sizing: border-box;
	
	.mc-fields-group {
		float: left;
		width: calc(100% - 70px);		
		padding: 0;
		
		@media (min-width:720px) and (max-width:880px) {
			float: none;
			width: 100%;
		}
		
		label {
			visibility: hidden;
			height: 0;
		}
		#mce-EMAIL {
			border-radius: 40px;
			color: $secondary;
			height: 39px;
			font-size: 0.875rem;
			padding: 0 22px 0 15px;
		}
		
		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: lighten($secondary,20%);
		}
		::-moz-placeholder { /* Firefox 19+ */
			color: lighten($secondary,20%);
		}
		:-ms-input-placeholder { /* IE 10+ */
			color: lighten($secondary,20%);
		}
		:-moz-placeholder { /* Firefox 18- */
			color: lighten($secondary,20%);
		}
		
		
		input {		
			
			&.mce_inline_error {
				border-width: 2px;
			}
		}
	}

	.mc-submit-button {
		float: left;
		margin-left: -40px;
		
		@media (min-width:720px) and (max-width:880px) {
			float: right;
			margin: 0;
		}
		
		input[type="submit"] {
			border-radius:40px;
			font: bold 1.125rem/1.16666666666667 $base-font-family;
			height: 39px;
			margin: 7px 0 0;
			padding: 0 15px;
			text-transform: uppercase;
			width: 100px;
		}
	}
}

