#site-footer {
	@media (min-width:$narrow) {
		.view-footer-sections {
			> .view-content-main {
				display: flex;
				justify-content: space-between;
				
				> .views-row {
					flex-basis: 100%;
					margin-right: $gutt;
					
					&:last-child {margin-right: 0;}
					
					
					@media (min-width:720px) and (max-width:880px) {
						&.views-row-first {
							flex-basis: 150%;
						}
					}
				}
			}
		}
	}
  .copyright {
    @include span-columns(4);
  }

  .utility-menu {
    @include span-columns(8);
	  
  }
}
