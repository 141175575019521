// HELP-LIBRARY
.page-help-library {
  // Evalmapper specific styles
  .view.view-display-id-evaluative {
    background: #2C988B;
    .views-row {
      padding: 10px 0 0 0 !important;
    }
    .counter-footer {
      display: block;
      margin-top: 8px;
      min-height: 50px;
      background: $evalmapper_counter_icon left 50% no-repeat, $evalmapper_counter_text 60% 50% no-repeat;
      background-color: #02AD96;
      @media (min-width: 720px) and (max-width: 1400px) {
        background: $evalmapper_counter_text 60% 50% / 160px no-repeat;
        background-color: #02AD96;
      }
    }
    a {
      color: white;
    }
    &:hover {
      background: $pine_green;
      .counter-footer {
        background-color: #008F78;
      }
    }
  }
}
.view-search-resources {
  //SORT

  .search-sort {
    position: absolute;
    right: 0;
    bottom: -75px;
    display: block;

    @media(max-width:539px){
      left:0;
    }

    select#edit-sort-by {
      min-width: 244px;
      height: 42px;
    }
  }
  .view-content ul {
    padding-left: 0;
  }
}

.view-search-resources {
  .views-exposed-form .views-widget-filter-search_api_views_fulltext input.form-text {
    border-radius: 40px;
    max-width: 80%;
    @media(min-width: 840px) {
      max-width: 85%;
    }
    @media(min-width: 1240px) {
      max-width: 90%;
    }
  }
}

// PORTALS

.views-row.portal {
  .card-image {
    @include span-columns(5);
    float: left;
    //margin-left: calc(-31.76157% + 30px);
    margin-top: 0;
    @media(max-width:719px) {
      float: none;
      width: 100%;
    }
  }
  .card-type+.card-text {
    clear: right;
  }
}

//RESOURCE TYPE VIDEO ICON

.views-row {
  &.resource-type-76 {
    a.card .card-type-resource:after {
      background: $ico-video-play no-repeat 50% 50% / auto 20px $mango_tango;
      height: 35px;
      width: 35px;
      margin-top: -30px;
      margin-left:95px;
      display: block;
      position: absolute;
    }
  }
}


// HERO
.page-help-library,
.page-help-library-new {
  .content-title {
    display: none;
  }
  .content-hero {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 0;
    // display: flex;
    // flex-flow: row wrap;
    display: block;
    @media(min-width:720px){
      display: flex;
      flex-flow: row wrap;
    }
    .block {
      @include span-columns(3);
      &:nth-child(5n) { margin-right:0; }
      &:nth-child(5n+1) { clear:left;}
      background: $platinum_blue;
      .views-row {
        text-align: center;
        padding: 30px 20px;
        .counter-figure,
        .counter-type {
          display: block;
          font-family: $title-font-family;
          font-weight: 500;        }
        .counter-figure {
          font-size: 50px;
          line-height: 50px;
        }
        .counter-type {
          font-size: 1.5rem;
        }
      }
      .view-display-id-submit {
        font-size: 28px;
        font-family: $title-font-family;
        font-weight: 500;
        height: 100%;
        a {
          color: #FFFFFF;
        }
        .view-content,
        .views-row {
          width: 100%;
          height: 100%;
          padding: 0;
        }
        .views-row {
          a {
            width: 100%;
            height: 100%;
            display: flex;
            display: -webkit-flex;
            justify-content: center;
            align-items: center;
            -webkit-align-items: center;
            padding: 20px;
            &::before {
              content: "";
              display: block;
              width: 32px;
              height: 31px;
              background: $ico-upload no-repeat scroll 50% 50%;
              margin-right: 20px;
            }
          }
        }
      }
    }
    .block:nth-last-child(1) {
      background: $mango_tango;
      &:hover {
        background: $medium_vermilion;
      }
    }
    .block:nth-child(1) {
      flex: 1 100%;
      width: 100%;
      margin-bottom: 20px;
      margin-right: 0;
      background: transparent;
      .help-header {
        display: table;
        height: 100%;
        @media(max-width:539px){
          display:block;
        }
        .help-logo {
          background: $logo-help;
          width: 210px;
          height: 210px;
          float: left;
          margin-right: 30px;
          @media(max-width:539px){
            margin-bottom:20px;
          }
        }
        .help-text {
          text-align: left;
          display: table-cell;
          vertical-align: middle;
          .help-intro {
            font-size: 28px;
            line-height: 33px;
          }
          .help-title {
            font-family: $title-font-family;
            font-size: 38px;
            font-weight: 500;
            line-height: 51px;
          }
        }
      }
    }
    @media (max-width: $narrow) {
      flex-direction: column;
      .block {
        width: 100%;
        margin: 0 0 20px 0;
      }
    }
  }
}
