.node-type-research-topic,
.node-type-event {
	.view-display-id-hero {
		.body-expand {
			overflow:hidden;
		}
		.jump-links {
			font-size: 1.125em;
			//position:absolute;
			margin-left:20px;
			margin-top:20px;
			// width:100%;
			// bottom:-50px;

			.view-header {
				display:inline-block;
				float:left;
				margin-right:20px;
				
				div { display: inline-block; }

				&:after {
					content:" ";
					background:$jump-arrow no-repeat 50% 50% / 17px auto;
					display: inline-block;
					height: 17px;
					width: 18px;
					vertical-align: middle;
					margin-left: 5px;
				}
			}

			.views-row {
				display: inline-block;
				margin: 0 10px 10px 0;
			}

			.card-title {
				text-align: left;
			}

			a.jump-btn {
				background-color:$action-default;
				color: white;
				padding: 5px 14px;
				border-radius: 20px;
				display: inline-block;
				// margin-right: 20px;

				&:hover {
					background-color:$action-hover;
					color: white;
				}
			}
		}
	}
}