form.webform-client-form,
form#user-profile-form {
	
	input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea, select[multiple=multiple], textarea {
		border-radius: 3px;
	}
	select {
		border-radius: 3px 40px 40px 3px;		
	}
	
	ul.token-input-list  {
		border-radius: 3px;
		padding: 3px 5px;
		li.token-input-token {
			padding: 5px 8px 5px 0;
		}
	}
	
	fieldset,
	#edit-account,
	#edit-field-about,
	#edit-field-user-picture {
		background: white;
		border: none;
		border-radius: $card-border-radius;
		box-shadow: $box-shadow;
		margin: 0 0 $vert-space*1.5;
		padding: $vert-space*1.5 $horz-space*1.5;

		legend {
			background: $weldon_blue;
			border: none;
			color: white;
			font: 1.75rem/1.35714285714286 $title-font-family;
			margin:0 -30px -20px;
			padding:  15px 30px 15px 30px;
			width: calc(100% + 60px);
		}
		
		fieldset {
			background: none;
			border: $primary-border;
			border-radius: none;
			margin: 0 0 $vert-space*1.5;
			padding: $vert-space $horz-space;

			legend {
				background: none;
				border: none;
				color: $independence;
				font-size: 	1.375rem;
				line-height:  1;
				margin:0 0 1rem;
				padding: 0;
				width: auto;
			}
		}
	} 
	
	
	.form-item {
			max-width: 600px;
		}

		#{$all-text-inputs} {
			background-color: $platinum;
			border: none;
			color: $independence;
			&[type="file"] {
				background-color: transparent;
			}
			&[type="button"],
			&[type="submit"] {
				@include button-link-default();
			}
		}
		select {
			min-width: 300px;		
			width: 75%;
		}
	
		label.option {
			font-size: 0.875rem;
			color: $independence;
		}
		&.webform-component-radios {
			width: 75%;
			max-width: 960px;
		}
		
		@media (min-width:$narrow) {
			.form-type-radio {
				display: inline-block;
				width: auto;
				max-width: calc(100%/3 - #{$gutter});
				margin-right: $gutter*1.5;

				&:nth-child(3n) {
					margin-right: 0;
				}
				
				label {
					margin-bottom: 0;
				}
			}
		}
	
	@media (min-width:$narrow) { 
		[class*="first-name"],
		[class*="middle-name"],
		[class*="last-name"] {
			display: inline-block;
			width: calc(33% - 21px);
			margin: 0 20px 20px 0;

			&:last-child {
				margin-right: 0;
			}
		}

		[class*="phone"],
		[class*="postal-code"],
		[class*="city"] {
			input {
				min-width: 300px;
				width: 75%;

			}
		}
	}
	
	input[readonly="readonly"] {
		background-color: $white_smoke;
		color: $trolley_grey;
	}
	
	.webform-component--employment--civicrm-2-contact-1-contact-organization-name,
	.webform-component--employment--civicrm-2-contact-1-cg1-custom-1 {
		padding-left: 30px;
		
		label {
			color: $trolley_grey;
		}
	}
	.webform-component--employment--civicrm-2-contact-1-cg1-custom-1 {
		margin-bottom: 40px;
	}
	
	#edit-locale {
		display: none;
	}
	
	#edit-picture {
		.user-picture {
			text-align: center;
		}
		@media (min-width:740px) {
			.user-picture {
				float: right;
				margin: 0 0 0 $horz-space*1.5;
			}
			input[type="file"] {
				width: auto;
			}			
		}

	}
	
	.password-indicator {
		margin-bottom: 8px;
	}
	
	#edit-field-about .form-type-textarea,
	#edit-field-user-picture .form-type-managed-file {
		margin: 0;
		max-width: none;
		
		label {
			background: $weldon_blue;
			border: none;
			color: white;
			font: 1.75rem/1.35714285714286 $title-font-family;
			margin:-30px -30px 20px;
			padding:  15px 30px 15px 30px;
			width: calc(100% + 60px);
		}		
	}
	
	.confirm-parent,
	.password-parent{
		width: 100%;
	}
	.password-strength {
		//width: 100%;
		max-width: 600px;
		
		@media (max-width:350px) { width: 100%; }
	}
	
	#edit-field-id,
	#edit-field-registration-webform-submi,
	#edit-field-details-updated {
		display: none;
	}
	
	.form-item .description {
		color: $secondary;
		margin-bottom: 10px;
	}

	.form-item {
		.webform-container-inline.webform-datepicker {
			display: flex;
			select {
				min-width: 180px;
				margin-right: 10px;
			}
			input.webform-calendar {
				height: 15px;
				width: 18px;
				margin-top: 1em;
				margin-bottom: 1em;
				padding-top: 10px;
				box-sizing: content-box;
			}
		}
	}
	
}
