@mixin flash($color) {
  background-color: $color;
  border: $message-border-style darken($color,10%);
  border-radius: $message-border-radius;
  color: darken($color, 60%);
  display: block;
  margin: $vert-space  $horz-space/2 ;
  padding: $vert-space/2 $horz-space/2;

  a {
    color: darken($color, 70%);
    text-decoration: underline;

    &:focus,
    &:hover {
      color: darken($color, 90%);
    }
  }
}

.messages {
  margin: 0 $horz-space/2;
	
  &.error {
    @include flash($error-color);
    display:none;
  }

  &.status {
    @include flash($success-color);
  }

  &.notice {
    @include flash($notice-color);
  }

  &.warning {
    @include flash($alert-color);
  }
}
