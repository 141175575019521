$card-text-padding : $vert-space $horz-space !default;
$card-margin-bottom : $vert-space !default;

.card {
	box-sizing:border-box;
	display:block;
  margin-bottom:$card-margin-bottom;
	overflow:hidden;
	position:relative;

}
.card-text {
	box-sizing:border-box;
	display:block;
	overflow:hidden;
	padding:$card-text-padding;
	
	> :last-child {
		margin-bottom:0;
	}
}
.card-body {
	*:last-child {
		margin-bottom:0;
	}
}
.card-image,
.card-video,
.card-photo,
.card-logo,
.card-cover-image,
.card-image-et,
.card-full-width-image,
.card-picture,
.card-image-URL,
.card-gif-image {
	display:block;
	line-height:0;
}
.card-image,
.card-photo,
.card-logo,
.card-cover-image,
.card-image-et,
.card-full-width-image,
.card-picture,
.card-image-URL,
.card-gif-image {
  text-align:center;
}
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}