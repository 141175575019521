.node-type-event {
	.view-display-id-location,
	.view-display-id-contact {
		background-color:white;
		padding:20px;
		margin-top:20px;
	}
	
	.view-display-id-members {
		.card-logo {
			margin-bottom:20px;
			padding: 0.75rem;
		}
	}
	
	.hide-block {
		display:none;
	}
	.header-title {
		margin-bottom: 30px;
	}
}
.view-member .section-row .card-logo {
	padding: 0.75rem;	
}
