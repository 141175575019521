// MENU FLASH FIX
// --- Set height for menu here to ensure menu loading causes minimal fuss
#site-header {
	.block-menu-block {
		display: none;
	}
	.main-nav-wrapper,
	.block-menu-block {
		@media (min-width:990px) and (max-width:1150px) { //880px
			min-height:34px;
		}

		@include media(1150px) {
			min-height:46px;
		}
	}
}

// MENU STYLES
#site-header {
	@include media(990px) { //880px
		.main-nav-wrapper {
			background: $nav-wrapper-bg-color;
		}
	}
	.block-menu-block {
		clear:right;
		.main-menu-btn {
			float:right;
			width:66px;
			height:56px;
			text-indent:-999em;
			margin:0;
			
			@media (max-width:989px) { //879px
			    margin: 0 0 10px;
			}
			
			.main-menu-btn-icon {
				left:15px;
				height:4px;
				width:36px;
				background-color:$nav-button-bg-color;
				border-radius:2px;
				margin-top:-2px;
				&:before {
					top:-14px;
					left:0;
					height:4px;
					width:36px;
					background-color:$nav-button-bg-color;
					border-radius:2px;
				}
				&:after {
					top:14px;
					left:0;
					height:4px;
					width:36px;
					background-color:$nav-button-bg-color;
					border-radius:2px;
				}
			}
		}
		#main-menu-state:checked ~ .main-menu-btn { background-color:$nav-base-bg-color; margin: 0 0 -5px; height: 71px;} //75
		#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon { height:4px; margin-top: -10px; background-color:$nav-hover-bg-color; }
		#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before,
		#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:0; transform:none; background-color:$nav-hover-bg-color; }
		#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before { top:-14px; }
		#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:14px; }
		ul.sm {
			background: none;
			clear:both;
			
			@include media(990px) { //880px
				display: flex;
				flex-wrap: nowrap;
				margin: 0px 105px 0 2%; //0px 75px 0 2%;
			}
				
			@include media(1150px) { 
				margin: 0px 105px 0 2%; //0px 75px 0 2%;
			}
		}
		@include media(990px) { //880px
			clear:right;
			/*float:right;*/
			.menu-block-wrapper > ul.menu > li > a > .sub-arrow { display:none; }
		}
		.menu-block-wrapper > ul.menu > li:last-child { position:relative; }
		
		.menu-block-wrapper > ul.menu > li {
			border-left:none;
			flex-grow: 1;
			text-align: center;
			
			
				
			&.menu-mlid-2885 a {
				background-image: $search-icon;;
				background-position: 1.125rem 50%;
				background-repeat: no-repeat;
				padding-left: 2.55rem;
				background-size: 13px;
				
				&:hover {
					background-image: $search-icon-hover;
				}
			}
			 
			@include media(990px) { //880px
				&.menu-mlid-2831 > a,
				&.menu-mlid-2882 > a{
					padding-left: 40px;
					background: $help-icon no-repeat calc(50% - 65px) 50% / 20px 20px $nav-base-bg-color; 
					
					&:hover,
					&.highlighted:hover,
					&.highlighted {
						padding-left: 40px;
					}
					
					&.highlighted {
						background: $help-icon-black no-repeat calc(50% - 65px) 50% / 20px 20px $nav-hover-bg-color;
					}

					&:hover {
						color:$nav-hover-text-color;
						background: $help-icon-alt no-repeat calc(50% - 65px) 50% / 20px 20px $nav-hover-bg-color;
					}
				}
				
				&.menu-mlid-2885 {
					display: none;
				}
			}
		}
		
		
		
		
		
		// IE 10+ ----------------------
	
		@include media(990px) { //880px
			_:-ms-lang(x), .menu-block-wrapper > ul.menu > li.menu-mlid-2831 > a {
			background: $help-icon no-repeat 12px 50% / 20px 20px $nav-base-bg-color;
			}

			_:-ms-lang(x), .menu-block-wrapper > ul.menu > li.menu-mlid-2831 > a.highlighted {
			background: $help-icon-black no-repeat 12px 50% / 20px 20px $nav-hover-bg-color;
			}

			_:-ms-lang(x), .menu-block-wrapper > ul.menu > li.menu-mlid-2831 > a:hover {
				background: $help-icon-alt no-repeat 12px 50% / 20px 20px $nav-hover-bg-color;
			}
		}
			
		@include media(1200px) {
			_:-ms-lang(x), .menu-block-wrapper > ul.menu > li.menu-mlid-2831 > a {
				background-position-x: 25px !important;
			}
		}
			
		@include media(1400px) {
			_:-ms-lang(x), .menu-block-wrapper > ul.menu > li.menu-mlid-2831 > a {
				background-position-x: 40px !important;
			}
		}

		//--------------------------------
				
		
		
		
		
		.menu-block-wrapper > ul.menu > li > a {
			background:$nav-sub-bg-color;
			font:$nav-base-font;
			color:$nav-sub-text-color;
			padding:$nav-sub-base-padding;
			
			text-transform: uppercase;
			
			text-align: left;
			//font-weight: 500;
			
			&:hover {
				background-color:$nav-hover-bg-color;
				color:$nav-hover-text-color;
			}
			
			@include media(990px) { //880px
				background-color:$nav-base-bg-color;
				font:$nav-base-font;
				color:$nav-base-text-color;
				padding:$nav-base-padding;
				
				text-align: center;
				//font-weight: 500;
			
				@media (min-width:990px) and (max-width:1150px) { //880px
					font: $nav-base-font-smaller;
					padding: $nav-base-padding-smaller;
				}
				
				&:hover,
				&.highlighted:hover {
					
					color:$nav-hover-text-color;
					
					@include media(990px) { //880px
						border-bottom: $nav-hover-boder;
						padding:$nav-base-hover-padding;

						@media (min-width:990px) and (max-width:1150px) { //880px
							padding:$nav-base-hover-padding-smaller;
						}
					}
				}
			}
			
			&.current {
				/*@include media(880px) {*/
					background-color:$nav-active-bg-color;
					color:$nav-active-text-color;
					border-radius:$nav-active-border-radius;
				/*}*/
			}
			
			&.highlighted {
				
				@include media(990px) { //880px
					background-color:$nav-highlight-bg-color;
					color:$nav-highlight-text-color;
					border-bottom: $nav-highlight-boder;
					padding:$nav-base-hover-padding;

					@media (min-width:990px) and (max-width:1150px) { //880px
						padding:$nav-base-hover-padding-smaller;
					}
				}
			}
			
									
			/////////////////////////////
			@media (max-width:989px) { //879px

				&:hover {
					background-color:$nav-sub-hover-bg-color;
					color:$nav-hover-text-color;
					.sub-arrow {
						background:$arrow-orange-down no-repeat scroll center center transparent;
						background-size:50%;
					}	
				}
				&.current { /* LISTO */
					background:$nav-sub-bg-color;
					color:$nav-sub-text-color;
					border-bottom: $nav-hover-boder;
					padding:$nav-sub-base-hover-padding;
					.sub-arrow {
						background:$arrow-dark-down no-repeat scroll center center transparent;
						background-size:50%;
					}
					&:hover {
						.sub-arrow {
							background:$arrow-orange-down no-repeat scroll center center transparent;
							background-size:50%;
						}
					}
				}
				
				&.active { /* LISTO */
					background-color:$nav-active-bg-color;
					color:$nav-active-text-color;
					.sub-arrow {
						background:$arrow-white-down no-repeat scroll center center transparent;
						background-size:50%;
						border-left-color: white;
						margin-top: -22px;
    				height: 46px;
					}

					&:hover {
						.sub-arrow {
							background:$arrow-white-down no-repeat scroll center center transparent;
							background-size:50%;
						}
					}
				}
				
				&.highlighted {
					background:$nav-sub-bg-color;
					color:$nav-sub-text-color;				
					border-bottom: $nav-hover-boder;
					padding:$nav-sub-base-hover-padding;
					.sub-arrow {
						background:$arrow-orange-up no-repeat scroll center center transparent;
						background-size:50%;
						
						height: 43px;
						margin-top: -22px;
					}
					&:hover {
						background-color:$nav-sub-hover-bg-color;
						color:$nav-hover-text-color;
						.sub-arrow {
							background:$arrow-orange-up no-repeat scroll center center transparent;
							background-size:50%;
						}	
					}
				}
				&.current.highlighted {
					background-color:$nav-active-bg-color;
					color:$nav-active-text-color;
					border-bottom: $nav-highlight-boder;
					padding:$nav-sub-base-hover-padding;
				}
				&.active.highlighted {
					.sub-arrow {
						background:$arrow-white-up no-repeat scroll center center transparent;
						background-size:50%;
					}
					&:hover {
						.sub-arrow {
							background:$arrow-white-up no-repeat scroll center center transparent;
							background-size:50%;
						}
					}
				} 
			}
			////////////////////////////////
		}
		
		@media (max-width:989px) { //879px
			ul.menu.sm > li.first.leaf {
				border: none;
			}
			ul.menu.sm {
				position: absolute;
				top: 74px; //78
				right: 0;
				width: calc(100% - 5%);
				max-width: 400px;
			}
		}

		ul.menu {
			border: $nav-mob-border;
			border-top: none;
			@include media(990px) { //880px
				border:none;
				box-shadow:none;
				&.sm {						
					border-top:none;
				}
			}
			li {
				@media (max-width:989px) { //879px
					border-top: $nav-mob-border;
				}
				
				a {
					cursor:pointer; 
					
					.sub-arrow {
						background:$arrow-dark-down no-repeat scroll center center transparent;
						background-size:50%;
						
						@include media(990px) { //880px
							background:$arrow-blue-right no-repeat scroll center center transparent;
							background-size:contain;
							width: 16px;
						}
						text-indent:-999em;
						overflow:hidden;
						right:0;
					}
				}
							
				@media (max-width:989px) { //879px
					&.expanded {
						ul.menu,
						ul.menu a.active,
						ul.menu a.current {
							background-color: $nav-sub-2nd-bg-color;
							
							li.expanded {
								a.highlighted,
								ul.menu,
								ul.menu a.active {	
									background-color: $nav-sub-3rd-bg-color;
									
									li:not(.first) {
										border-top:none;
									}
								}
							}
						}
					}
					
					a {
						.sub-arrow {
							height: 46px;
							margin-top: -23px;
							border-left: $nav-mob-border;
						}
					}

					li {
						a {
							.sub-arrow {
								height: 39px;
								margin-top: -19px;
								border-left: $nav-mob-border;
							}
						}
					}
				}
				
				
				ul.menu {
					
					background:$nav-sub-bg-color;
					@include media(990px) { //880px
						box-shadow:$nav-sub-box-shadow;
					}
					border:$nav-sub-border;
					border-radius:$nav-sub-border-radius;
					
					li {
						/*border-top:none;*/
						
						&.active {
							border-left: 4px solid $nav-hover-text-color;
						}
						
						a {
							color:$nav-sub-text-color;
							/*font-size:1.125rem;*/
							
							font: $nav-sub-base-font;

							line-height:1.225;
							padding:$nav-sub-base-padding;
							
							&.current {
								background:$nav-sub-bg-color;
							}
							&:hover, &.active {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
							} 
							.sub-arrow {
								background:$arrow-dark-down no-repeat scroll center center transparent;
								background-size:50%;
								@include media(990px) { //880px
									background:$arrow-blue-right no-repeat scroll center center transparent;
									background-size:contain;
									right:10px; 
								}
								text-indent:-999em;
								overflow:hidden;
								right:0;
							}
							
							&.active {
								.sub-arrow {
									background:$arrow-blue-up no-repeat scroll center center transparent;
									background-size:50%;
									@include media(990px) { //880px
										background:$arrow-blue-right no-repeat scroll center center transparent;
										background-size:contain;
									}
								}
							}
							&:hover, &.active:hover  {
								.sub-arrow {
									background:$arrow-orange-down no-repeat scroll center center transparent;
									background-size:50%;
									@include media(990px) { //880px
										background:$arrow-blue-down no-repeat scroll center center transparent;
										background-size:contain;
									}
								}								
							}
						}
						&.expanded > a.highlighted {
							background-color:$nav-hover-bg-color;
							color:$nav-hover-text-color;
							.sub-arrow {
								background:$arrow-orange-up no-repeat scroll center center transparent;
								background-size:50%;
								@include media(990px) { //880px
									background:$arrow-blue-down no-repeat scroll center center transparent;
									background-size:contain;
								}
							}
							&:hover, &.current {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
							}
						}
					}
				}
			}
		}
	}
	#main-navigation .menu-position-link {
		display: none;
	}
}


