html body.admin-menu {
	margin-top: 0px !important;
	padding-top: 29px !important;
}

.domain-alnap-dev-mrmdev-co-uk .site-title{
	margin-top: 10px;
	@media(min-width: 990px) {
		margin-top: 20px;
	}
}

.i18n-en .site-title a {
	background: url("../images/logo.svg") no-repeat scroll 0 50% transparent;
}

//.i18n-en.front .site-title a { background:$logo-en-25 no-repeat scroll 0 0 transparent; }

body.domain-alnap-dev-mrmdev-co-uk {
	#site-header .site-branding .site-title {
		@media(min-width: 768px) {
			border-right: 3px solid #FF7F40; 
		}
	}
}

#site-header {
	@include clearfix;
	background-color:$header-bg-color;

	@media (max-width:989px) { //879px
		border-bottom: 8px solid $nav-base-bg-color;
	}
	//
	// /* Change .header-outer-wrapper to
	// .header-inner-wrapper, .main-navigation
	// to make the menu a band in it's own right. */
	//
	.header-inner-wrapper {
		@media (max-width:989px) { //879px
			margin-bottom: 5px;
		}
	}
	.header-inner-wrapper,
	.main-navigation {
		position:relative;
		@include outer-container($site-max-width);

		@media (max-width:989px) { //879px
			display: flex;
			flex-direction: column-reverse;

			.wrapper-region-header {
				display: flex;
				flex-direction: row-reverse;
				justify-content: center;
			}
		}
	}
	.site-branding {
		margin-top:2.5rem;
		margin-left:1.25rem;
		position: relative;
		@media(min-width: 768px) {
			margin-top:1.5rem;
		}
		@media(990px) { //880px
			top:0;
		}
		.site-title {
			position:absolute;
			left:0;
			z-index: 999;
			top: -10px;

			@media(min-width: 990px) {
				top: 0;
			}
			a {
				display:block;
				text-indent:-999em;
				overflow:hidden;
				height: 52px;
				width: 240px;
				margin-right: 20px;

				@media(min-width: 768px) and (max-width: 990px) {
					width: 178px;
				}

				@media (min-width:990px) { //879px
					width:172px;
					height:58px;
				}
			}
		}
		.site-slogan {
			display: none;
			@media(min-width: 768px) {
				display: block;
				font-family: $base-font-family;
				font-size: 18px;
				font-weight: 400;
				position:absolute;

				max-width: 300px;
			}
			@media(min-width: 768px) and (max-width: 990px) {
				left:220px;
				top:0;
			}
			@media(min-width: 990px) {
				left:215px;
				top:24px;
			}
		}

	}
	.search-toggle-wrapper {
    position: absolute;
    top: 0;
    right: 0;
		display:none;

		@include media(990px) { //880px
			display: block;

			.search-toggle {
				height: 46px;
				width: 75px;
				/*position: absolute;
				top: -46px;
				top: 0px;
				right: 0;*/
				text-indent: -9999px;
				background: transparent $search-icon-white no-repeat scroll 50% 50%;

				@media (min-width:990px) and (max-width:1150px) { //880px
					height: 39px;
					top: -39px;
				}

				&:hover {
					background: $nav-hover-bg-color $search-icon-hover no-repeat scroll 50% 50%;
					border-bottom: $nav-hover-boder;
					cursor: pointer;
				}

				&.main {
					background: $nav-hover-bg-color $close-icon no-repeat scroll 50% 50%;
					border-bottom: $nav-highlight-boder;

					&:hover {
						background: $nav-hover-bg-color $close-icon-hover no-repeat scroll 50% 50%;
						border-bottom: $nav-hover-boder;
					}
				}
			}
		}
	}
	.block-search {
		display:none;


		@include media(990px) { //880px
			display: block;
			position: absolute;
			right: 0;
		}

		#search-block-form {
			display:none;

			@include media(990px) { //880px
				position: absolute;
				right: 0;
				top: 0;
				background: $header-search-bg-color;
				z-index: 1;
				padding: 10px;
			}

			h2 {
				display:none;
			}
			.form-item-search-block-form {
				margin: 0;
				float:left;

				input.form-text {
					width:26rem;
					height:2.5rem;
					background-color:$header-search-field-bg-color;
					color:$header-search-field-text-color;
					box-shadow:$header-search-field-box-shadow;
					border:$header-search-field-border;
					border-radius:$header-search-field-border-radius;
					font:$header-search-field-font;
					padding:0.375rem 0.75rem;
					margin: 0;
					font-size: 1rem;
				}
			}
			.form-actions {
				float:left;
				margin:0;
				input.form-submit {
					background: transparent $search-icon no-repeat scroll 0 0;
					background-size:18px;
					width:18px;
					height:18px;
					text-indent:-999em;
					overflow:hidden;
					padding:0;
					position:absolute;
					right:1.25rem;
					top:1.25rem;

					&:hover {
						background: transparent $search-icon-hover no-repeat scroll 0 0;
						cursor: pointer;
					}
				}
			}
		}
	}
	.block-menu {
		float:right;
		//margin-top:1.25rem;
		margin-top: 1.125rem;

		@include media(990px) { //880px
			margin-bottom:3.5rem;
			margin-right:0.5rem;
		}

		ul.menu {
			margin: 11px 0 0;
			//margin: 0;

			@media (max-width:989px) { //879px
				padding-left: 0;
				margin: 12px 0 0;
			}

			li {
				display:inline-block;
				margin:0 0.25rem 0.750rem 0;

				&:last-child {
					margin-right: 0;
				}

				@include media(990px) { //880px
					margin:0 0.75rem 0 0;

					display: inline-table;
    			vertical-align: middle;
				}

				ul {
					display: none;
				}

			}

			a {
				// font:$header-menu-font;
				background-color:$header-menu-link-color;
				border-radius: 20px;
				color:white;
				font-size: 0.875rem;
				outline: none;
				padding: 8px 10px;


				@include media(540px) {
					font-size: 1rem;
					padding: 8px 20px;
				}


				&:hover {
					color:white;
					background-color:$header-menu-link-hover;
				}
			}

			a[href^="/user/logout"] {
				color:white;
				background-color:$card-links-action-bg;

				&:hover {
					color:white;
					background-color:$card-links-hover-bg;
				}
			}

			//a[href^="/our-topics/me-community-of-practice"],
			//a[href^="/node/70406"],
      a[href^="/me-log"],
      a[href^="/node/68096"] {
				color:white;
				background-color:$persian_green;

				&:hover {
					color:white;
					background-color:$pine_green;
				}
			}
		}
	}

  .block-menu.portal-admin-menu {
    float: left !important;
    margin-bottom: 1em !important;

    ul.menu li {
      margin: 0 0.25rem 0.750rem 0 !important;

      a {
        background-color: black;
        font-size: 14px;

        &:hover {
          background-color: $secondary;
        }
      }
    }
  }

	.block-gtranslate {
		float:right;
		margin-top:1.25rem;
		margin-top: 1.5rem;
		margin-right:0.5rem;

		select {
			width: 100%;
			margin: 0;
			padding: 0 17px 0 12px;
			box-sizing: border-box;
			font-size: 0.75rem;
			height: 35px;
			background-size: 28px 28px;

			@include media(540px) {
				background-size: 35px 35px;
				height: 35px;
				font-size: 0.875rem;
				padding: 0 40px 0 20px;
				height: 35px;
			}
		}

		@include media(990px) { //880px
			margin-bottom:3.5rem;
		}

	}

	.view-header-sections {
		display: none;

		@include media(1200px) {
			display: block;
		}
		float:right;
		margin-top:1rem;
		margin-right:0.5rem;

		.section-row {
			padding: 0;
		}

		.card-follow-us-on ul li { display: inline-block; }

		.card-links {
			display: inline-block;
			margin-right: 10px;
			text-indent: -9999px;
			width: 48px;

			a {
				background-color: $card-links-action-bg;
				border-radius: $card-links-border-radius;
				width: $card-links-width;
				height: $card-links-height;
				display:block;
				margin:0;
				outline: none;

				&:hover {
					opacity: $card-links-hover-opacity;
					border-radius: $card-links-hover-border-radius;
					background-color: $card-links-hover-bg;
				}
			}

			/* newsletter */
			.newsletter a,
			a[href^="http://eepurl.com"] {
				background: $ico-newsletter no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
				&:hover {
					background: $ico-newsletter-over no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
				}
			}

			/* linkedin */
			.linkedin a,
			a[href^="https://www.linkedin.com"],
			a[href^="http://www.linkedin.com"],
			a[href^="https://linkedin.com"],
			a[href^="http://linkedin.com"] {
				background: $ico-linkedin-white no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
				&:hover {
					background: $ico-linkedin-white no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
				}
			}

			/* twitter */
			.twitter a,
			a[href^="https://www.twitter.com"],
			a[href^="http://www.twitter.com"],
			a[href^="https://twitter.com"],
			a[href^="http://twitter.com"] {
				background: $ico-twitter-white no-repeat scroll 50% 50% / 20px 20px $card-links-action-bg;
				&:hover {
					background: $ico-twitter-white no-repeat scroll 50% 50% / 20px 20px $card-links-hover-bg;
				}
			}

			/* facebook */
			.facebook a,
			a[href^="https://www.facebook.com"],
			a[href^="http://www.facebook.com"],
			a[href^="https://facebook.com"],
			a[href^="http://facebook.com"] {
				background: $ico-facebook-white no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
				&:hover {
					background: $ico-facebook-white no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
				}
			}

			/* google+ */
			.g-plus a,
			a[href^="https://www.plus.google.com"],
			a[href^="http://www.plus.google.com"],
			a[href^="https://plus.google.com"],
			a[href^="http://plus.google.com"] {
				background:$ico-gplus-white no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
				&:hover {
					background: $ico-gplus-white no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
				}
			}

			/* youtube */
			.youtube a,
			a[href^="https://www.youtube.com"],
			a[href^="http://www.youtube.com"],
			a[href^="https://youtube.com"],
			a[href^="http://youtube.com"] {
				background:$ico-youtube-white no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
				&:hover {
					background: $ico-youtube-white no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
				}
			}
		}
	}
	.block-locale {
		clear:both;
		display:none;
	}
}
