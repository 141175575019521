.content-hero,
.postscript-first .view-display-id-subtopic {
	.section-row .section-inner {
		margin: 0 auto;
		max-width: 1600px;
		padding: 0;
		position: relative;
		
		.card-original,
		.card-image-et,
		.card-logo {
			@media(min-width:$narrow) {
				float: left;
			}
/*			@media(min-width:540px) and (max-width:$narrow) {
				max-width: 40%;
			}*/

			@media(min-width:$narrow) and (max-width:959px) {
				max-width: 40%;
			}
			@media(min-width:$normal) and (max-width:$wide) {
				max-width: 50%;
			}

			z-index:3;
			position:relative;
			text-align: center;
		}
		
		.card-original {
			@media(max-width:800px) {
				margin-bottom:-7px;				
			}
			@media(max-width:719px) {
				.caption {
					//margin-bottom: 15px;
					background: white;
				}
			}		}
		.card-body,
		.card-description-et {
			background-color:white;
			border-bottom-right-radius: 20px;
			color:$primary;
			font-size: 1.125em;
			padding:30px 80px 30px 30px;
		}
		
		&.section-inner {
			@include clearfix;
		}
	}
}

.body-expand,
.body-expand-none {
	border-bottom-right-radius: 20px;
	position:relative;
	margin-bottom:20px;
	overflow:hidden;
	
	&:last-child {
		margin-bottom: 0;
	}
		.card-body,
		.card-description-et {
			overflow:hidden;
		}
		&.expanded {
			.card-body {
				//max-height:none;
			}
		}
		span a.morelink {
			background: $plus no-repeat scroll 50% 50% / 21px auto $mango_tango;
			border-radius: 50%;
			display:inline-block;
			height: 54px;
			width: 54px;
			cursor: pointer;
			text-indent:-9999px;
			float: right;
			margin: -74px 20px 20px 0px;
			
			&:hover {
				background-color: $medium_vermilion;
			}
		}
		
		span a.morelink.less {
			background: $minus no-repeat scroll 50% 50% / 28px auto $mango_tango;
			&:hover {
				background-color: $medium_vermilion;
			}
		}
}
