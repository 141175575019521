$body-margin: 0 !default;
$body-padding: 0 !default;


body {
  margin: $body-margin;
  padding: $body-padding;
}

.page {
  display: block;
  overflow: hidden;
}

.outer-wrapper {
  @include outer-container($site-max-width);
  width: 100%;
  box-sizing: border-box;
}

.equal-heights a {
  height: 100%;
  display: block;
}

.card-equal-heights {
  display: none;
}

img,
picture,
media,
video {
  height: auto !important;
  margin: 0;
  max-width: 100%;
  line-height: 0;
}

.limited-text-width {
  .card-title,
  .card-body,
  .card-description-et  {
    @media (min-width: 880px) {
      padding: 0 10% 0 0;
    }
    @media (min-width: $normal) {
      padding: 0 12.5% 0 0;
    }
    @media (min-width: $wide) {
      padding: 0 16% 0 0;
    }
  }
}

.item-list ul li {
  margin: 0;
  padding: 0;
}

.not-front {
  .postscript-first {
    clear: both;
    overflow: hidden;
  }
}

// SPAN-COL-#

.views-row:not(.view-type-slider) {

  @media (min-width: 540px) and (max-width: 719px) {

    &.views-row.span-col-12, &.views-row.span-col-6, &.views-row.span-col-4, &.views-row.span-col-3, &.views-row.span-col-2 {
      margin-right: 0;

      .card {
        margin-bottom: $vert-space;
        margin-right: 0;
      }
    }

    &.span-col-1 .views-row {
      @include span-columns(1);

      &:nth-child(12n+1) {
        clear: left;
      }

      &:nth-child(12n) {
        margin-right: 0;
      }
    }

    &.views-row.span-col-2,
    &.views-row.span-col-3,
    &.views-row.span-col-4,
    &.views-row.span-col-6 {
      @include span-columns(6);

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(2n+1) {
        clear: left;
      }
    }


    &.views-row.span-col-1 .card-last-link-url {
      @include span-columns(1);
    }

    &.views-row.span-col-2 .card-last-link-url,
    &.views-row.span-col-3 .card-last-link-url,
    &.views-row.span-col-4 .card-last-link-url,
    &.views-row.span-col-6 .card-last-link-url,
    &.views-row.span-col-2 .card-more-link,
    &.views-row.span-col-3 .card-more-link,
    &.views-row.span-col-4 .card-more-link,
    &.views-row.span-col-6 .card-more-link {
      @include span-columns(6);
    }
  }

  @include media($narrow) {

    &.views-row.span-col-12, &.views-row.span-col-6, &.views-row.span-col-4, &.views-row.span-col-3, &.views-row.span-col-2 {
      margin-right: 0;

      .card {
        margin-right: 0;
      }
    }

    &.views-row.span-col-1 {
      @include span-columns(1);

      &:nth-child(12n+1) {
        clear: left;
      }

      &:nth-child(12n) {
        margin-right: 0;
      }
    }

    &.views-row.span-col-3 {
      @include span-columns(3);

      &:nth-child(4n) {
        margin-right: 0;
      }

      &:nth-child(4n+1) {
        clear: left;
      }
    }

    &.views-row.span-col-4 {
      @include span-columns(4);

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(3n+1) {
        clear: left;
      }
    }

    &.views-row.span-col-6 {
      @include span-columns(6);

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(2n+1) {
        clear: left;
      }
    }

    &.views-row.span-col-1 .card-more-link,
    &.views-row.span-col-1 .card-last-link-url {
      @include span-columns(1);
    }

    &.views-row.span-col-2 .card-more-link,
    &.views-row.span-col-2 .card-last-link-url {
      @include span-columns(2);
    }

    &.views-row.span-col-3 .card-more-link,
    &.views-row.span-col-3 .card-last-link-url {
      @include span-columns(3);
    }

    &.views-row.span-col-4 .card-more-link,
    &.views-row.span-col-4 .card-last-link-url {
      @include span-columns(4);
    }

    &.views-row.span-col-6 .card-more-link,
    &.views-row.span-col-6 .card-last-link-url {
      @include span-columns(6);
    }
  }

  //If row-max-6 is selected but the page is under 960px in width, show 3 items per row instead
  @media (min-width: $narrow) and (max-width: 959px) {
    &.views-row.span-col-2 {
      @include span-columns(4);

      &:nth-child(3n) {
        margin-right: 0;
        clear: none;
      }

      &:nth-child(3n+1) {
        clear: left;
      }
    }
  }

  //If span-col-2 is selected but the page has a sidebar, show 3 items per row instead (in content-main and content-additional only)

  @include media($normal) {
    &.views-row.span-col-2 {
      @include span-columns(2);

      &:nth-child(6n) {
        margin-right: 0;
      }

      &:nth-child(6n+1) {
        clear: left;
      }
    }
  }

  @media (min-width: 500px) and (max-width: $normal) {
    .card-photo {
      float: left;
    }
  }


}

@include media($normal) {
  .one-sidebar,
  .two-sidebars {
    .content-main,
    .content-additional {
      .views-row:not(.view-type-slider) {
        &.views-row.span-col-2 {
          @include span-columns(4);

          &:nth-child(3n) {
            margin-right: 0;
          }

          &:nth-child(3n+1) {
            clear: left;
          }

          &:nth-child(6n) {
            margin-right: inherit;
          }

          &:nth-child(6n+1) {
            clear: none;
          }
        }
      }
    }
  }
}

//If span-col-3 is selected but the page is under 1200px in width and has sidebars, show 2 items per row instead
@media (min-width: $narrow) and (max-width: $wide) {
  .one-sidebar,
  .two-sidebars {
    .content-main,
    .content-additional {
      .views-row:not(.view-type-slider) {
        &.views-row.span-col-3 {
          @include span-columns(6);

          &:nth-child(2n) {
            margin-right: 0;
            clear: none;
          }

          &:nth-child(2n+1) {
            clear: left;
          }
        }
      }
    }
  }
}

@media (min-width: 540px) and (max-width: $wide) {
  .span-col-12 .card-logo,
  .span-col-12 .card-image {
    @include span-columns(3);
    margin-right: 0;
  }
  .one-sidebar,
  .two-sidebars {
    .content-main,
    .content-additional {
      .span-col-12 .card-logo,
      .span-col-12 .card-image {
        @include span-columns(4);
        margin-right: 0;
      }
    }
  }
}

@media (min-width: $wide) {
  .span-col-12 .card-logo,
  .span-col-12 .card-image {
    @include span-columns(2);
    margin-right: 0;

  }
  .one-sidebar,
  .two-sidebars {
    .content-main,
    .content-additional {
      .span-col-12 .card-logo,
      .span-col-12 .card-image {
        @include span-columns(3);
        margin-right: 0;
      }
    }
  }
}