.evaluation-filters {
  background: white;
  display: flex;
  padding: 20px 30px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  flex-wrap: wrap;
  @media(min-width:$normal) {
    flex-wrap: nowrap;
  }
  #eval_sub_level {
    width: 275px;
  }
  #loas_select {
    width: 275px;
  }
  .loa_checkboxes {
    margin-bottom: 20px;
    margin-right: 20px;
    @media(min-width:$normal) {
      margin-bottom: 0;
    }
    .loas-wrapper {
      display: flex;
      flex-direction: column;
      @media(min-width:$normal) {
        flex-direction: row;
      }
      .checkbox-wrapper {
        display: flex;
        margin-right: 40px;
        input {
          display: none;
        }
        label {
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #252422;
          background: $checkbox-alt no-repeat 0 50%;
          padding-left: 26px;
          &:hover {
            background: $checkbox-alt-hover no-repeat 0 50%;
          }
        }
        input:checked + label {
          background: $checkbox-alt-selected no-repeat 0 50%;
        }
      }
    }
  }
  .eval-sub-levels-wrapper {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .years-filters-wrapper {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    .year-from-wrapper,
    .year-to-wrapper {
      margin-bottom: 20px;
      @media(min-width:$normal) {
        margin-bottom: 0;
      }
    }
    @media(min-width:$narrow) {
      flex-direction: row;
    }
    @media(min-width:$normal) {
      margin-bottom: 0;
    }
  }
  .year-from-wrapper,
  .year-to-wrapper {
    margin-right: 20px;
    label {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: #252422;
      margin-bottom: 18px;
    }
    select {
      min-width: 130px;
      font-size: 16px;
      line-height: 19px;
      color: #252422;
      border: 1px solid #7596AE;
      background: $arrow-button-down-alt-3 no-repeat 100% 50% / 35px 35px;
      background-color: white;
      &:disabled {
        opacity: 0.6;
      }
    }
  }
  .year-to-wrapper {
    margin-right: 20px;
  }
  #map-reset,
  #heatmap-reset {
    margin-top: 20px;
    align-self: baseline;
    height: 35px;
    line-height: 1;
    padding: 8px 30px;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    background: #0E6794;
    @media(min-width:$narrow) {
      margin-top: 0;
      margin-bottom: 22px;
      align-self: flex-end;
    }
    &:hover {
      background: #40525F;
    }
  }
  .filter-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #252422;
    margin-bottom: 18px;
  }
}
#evaluation-heatmap-wrapper,
#evaluation-sectors-heatmap-wrapper {
  .highcharts-container {
    margin: 0;
  }
}
#evaluation-map-wrapper {
  height: 330px;
  @media(min-width:$normal) {
    height: 660px;
  }
  .highcharts-container {
    margin: 0;
    .highcharts-tooltip {
      div {font-family: $base-font-family;}
      .region-name {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
      }
      .region-resources {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}
section.block-evaluation-map {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}
body.node-type-region {
  section.postscript-first {
    background: #E6E6E6;
    .postscript_first {
      max-width: 1360px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 10px;
    }
  }
}
#heatmap-resources-wrapper {
  margin: 20px 0;
  .heatmap-resources-crumb {
    .country-tag-breadcrumb {
      display: flex;
      font-weight: 500;
      font-size: 30px;
      line-height: 35px;
      color: #252422;
      margin-bottom: 20px;
      .crumb-country {
        &:after {
          content: '';
          display: inline-block;
          height: 22px;
          width: 48px;
          background: $bc-arrow no-repeat 50% 50%;
        }
      }
    }
    .crumb-loa {
      margin-bottom: 10px;
    }
    .crumb-years {
      margin-bottom: 30px;
    }
    .crumb-loa,
    .crumb-years {
      font-size: 26px;
      line-height: 30px;
      color: #252422;
      .label {
        font-weight: 500;
      }
    }
    .resource-counter {
      font-size: 18px;
      line-height: 21px;
      color: #252422;
      margin-bottom: 10px;
    }
  }
  .heatmap-resources {
    .heatmap-resource {
      background: white;
      h3 {
        color: #0e6794;
      }
      .card-card-authors {
        color: #403c38;
      }
      &:hover {
        h3 {
          color: #ff8838;
        }
        .card-card-authors {
          color: #403c38;
        }
      }
    }
  }
}
.highcharts-axis-labels text,
.highcharts-axis-labels text tspan,
.highcharts-label tspan {
  font-family: $base-font-family !important;
}
// .highcharts-xaxis-labels {
//   text:nth-last-child(-n+7) {
//     fill: red !important;
//   }
// }
.block.block-evaluation-heatmap {
  padding-top: 50px;
  overflow-x: scroll;
  background: white;
  .back-to-map {
    position: absolute;
    top: 10px;
    left: 10px;
    a {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: #0E6794;
    }
    &:before {
      content: '';
      width: 20px;
      height: 22px;
      display: inline-block;
      background: $btp-arrow no-repeat 0 50%;
      vertical-align: sub;
    }
  }
}
.postscript_first .block.block-evaluation-heatmap {
  background: transparent;
}
#evaluation-heatmap-wrapper,
#evaluation-sectors-heatmap-wrapper {
  width: 1400px;
  margin: 0 auto;
  overflow-x: scroll !important;
}

#heatmap-sticky-header {
  display: none;
  // display: flex;
  // position: static;
  // position: fixed;
  z-index: 1;
  background: white;
  // display: block;
  top: 0;
  margin-left: 160px;
  width: 1231px;
  justify-content: space-around;
  border-bottom: 1px solid #ccd6eb;
  @media(min-width:1600px) {
    margin-left: 250px;
  }
  div {
    width: 100%;
    text-align: center;
    color: #40525F;
    font-size: 14px;
    padding: 20px 5px;
    border-right: 1px solid #ccd6eb;
    word-break: break-word;
    &:first-child {
      border-left: 1px solid #ccd6eb;
    }
  }
  &.show-sticky {
    display: flex;
    position: fixed;
  }
}

#evaluation-sectors-heatmap-wrapper {
  display: none;
}

.switch-wrapper {
  margin-right: 20px;
  @media(min-width:$narrow) {
    margin-right: 40px;
  }
  @media(min-width:$normal) {
    margin-right: 80px;
  }
  label.category {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #252422;
    margin-bottom: 18px;
  }
  .switch {
    label {
      cursor: pointer;
      display: inline-block;
      margin-bottom: .5rem;
    }
    input {
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;
      &:checked + .lever:after {
        left: 1.5rem;
        background-color: #DF573B;
      }
    }
    span.lever {
      position: relative;
      display: inline-block;
      margin: 0 1rem;
      margin-right: .625rem;
      vertical-align: middle;
      content: "";
      background-color: #D9E2E8;
      border-radius: .9375rem;
      width: 2.5rem;
      height: .9375rem;
      transition: background .3s ease;
      &:after {
        position: absolute;
        top: -.1875rem;
        left: -.3125rem;
        display: inline-block;
        content: "";
        background-color: #40525F;
        border-radius: 1.3125rem;
        box-shadow: 0 0.0625rem 0.1875rem 0.0625rem rgba(0,0,0,0.4);
        width: 1.3125rem;
        height: 1.3125rem;
        transition: left .3s ease,background .3s ease,-webkit-box-shadow 1s ease;
        transition: left .3s ease,background .3s ease,box-shadow 1s ease;
        transition: left .3s ease,background .3s ease,box-shadow 1s ease,-webkit-box-shadow 1s ease;
      }
    }
  }
}

#evaluation_map_link_side {
  margin: 20px 0;
  a {
    display: block;
    .map-bg {
      background: $evalmapper_rhs no-repeat;
      background-size: contain;
      width: 100%;
      height: 135px;
      .map-text {
        display: none;
      }
    }
    &:hover {
      .map-bg {
        background: $evalmapper_rhs_hover no-repeat;
        background-size: contain;
        width: 100%;
        height: 135px;
      }
    }
  }
}
#evaluation_map_link {
  a {
    background-color: #FF8838;
    border: none;
    border-radius: 40px;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font: 600 16px "Roboto","Open Sans","Segoe UI",Tahoma,sans-serif;
    padding: 0.75em 1em;
    text-decoration: none;
    text-transform: uppercase;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    position: relative;
    z-index: 1;
    transition: background-color 300ms linear 0s;
    &:hover {
      background-color: #DF573B;
      color: #fff;
    }
  }
}
.node-type-resource {
  #evaluation_map_link {
    text-align: center;
    margin-bottom: 30px;
  }
}

// EVALMAPPER Header
body.evaluation-map {
  .content-title {
    background: #00998C $evalmapper_banner 50% 50% no-repeat;
    min-height: 178px;
    h1 {
      display: none;
    }
  }
}

// // EVALMAPPER Header
// body.me-cops {
//   .content-title {
// 		background: linear-gradient(90deg, #00AD96 50%, #00998C 51%);
// 		padding: 0;
//     .outer-wrapper {
// 			background: transparent $evalmapper_bannerV2 0% 50% no-repeat;
//     	height: calc(100vw * 0.145);
// 			@media (min-width:1200px) {
// 				height: 180px;
// 			}
// 	    h1 {
// 	      display: none;
// 	    }
// 		}
//   }
// }