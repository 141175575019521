#breadcrumb {
	background:$breadcrumb-bg-color;
	padding:$vert-space/2 0;
	
	@media(max-width:$narrow) {
		padding:$vert-space/4 0;
		/*margin-top: 5px;*/
	}
	.breadcrumb {
		color:$breadcrumb-active-color;
		display:inline-block;
		font:$breadcrumb-font;
		font-weight: 500;
		line-height: 1.5;
		margin:$vert-space/4 0;

		padding:0 $horz-space/2;
		
		@media(max-width:$narrow) {
			padding:0 $vert-space/2;
		}
		span {
			font-size:0.875rem;
		
			&:after {
				color:$breadcrumb-div-color;
				content:"";
				display: inline-block;
				vertical-align: middle;
				margin-left:$horz-space/2;
				margin-right:$horz-space/2;
				height: 12px;
				width:8px;
				background: $arrow-grey-right no-repeat scroll 50% 50% / contain;
			}
		
			a {
				background:none;
				border:none;
				color:$breadcrumb-action-color;
				//background:$div-breadcrumbs no-repeat 100% 50%;
				
				&:hover {
					color:$breadcrumb-hover-color;
				}
			}
			
			&.first a {
				padding-left: 0;
			}
			
			&.last {
				//padding-left: 15px;
				
				&:after {
					content:normal;
				}
			}
		}
	}
}
