// GENERAL / RESET STYLES

//.front .page > main { display:none; }

.section-row.section-with-slider { padding:0; }

.section-row.section-with-slider .section-inner {
	margin: 0 auto;
	max-width:$site-max-width;
	padding:0;
	position: relative;
}

.slick-slide { margin-left:10px; margin-right:10px; }

// /* COMMON CONTROL/NAV STYLES */
.slick-controls, .slick-nav {
	
	button.slick-arrow {
		bottom:17px;
		z-index:1000;
		text-indent:-999em;
		font-family:$base-font-family;
		overflow:hidden;
		padding: 20px;
		&:before {
			content:"";
		}
		
		&.slick-prev,
		&.slick-next{
			background-size:contain;
			position: absolute;
			border-radius: 50%;
			padding: 16px;
    		background-size: 10px auto !important;
		}
		&.slick-prev {
			left:1.25rem;
			/*@include media($narrow) {
				left:0;
			}*/
		}
		&.slick-next {
			right:1.25rem;
			/*@include media($narrow) {
				right:0;
			}*/
		}
	}
	.slick-dots {
		padding:0 20px;
		li {
			width:14px;
			height:14px;
			margin:5px;
			button {
				display:inline-block;
				width:6px;
				height:6px;
				border-radius:50%;
				&:before {
					content:"";
					display:none;
				}
			}
		}
	}	
}

// /* SLICK CONTROLS ONLY -- everywhere but slides view which uses .slick-nav class instead
.slick-controls {
	height:56px;
	position:relative;
	
	@include media($narrow) {
		display:inline-block;
	}

	button.slick-arrow {
		bottom:17px;
		padding: 15px;
	}
	
	.slick-dots {
		bottom:17px;
		
		// iPad in portrait & landscape
		@media only screen 
		and (min-device-width : 768px) 
		and (max-device-width : 1024px)  {
			bottom:0px;
		}
	
		//Retina iPad in portrait & landscape
		@media only screen 
		and (min-device-width : 768px) 
		and (max-device-width : 1024px)
		and (-webkit-min-device-pixel-ratio: 2) {
			bottom:0px;
		}
		
		//iPad 1 & 2 in portrait & landscape
		//iPad mini in portrait & landscape
		@media only screen 
		and (min-device-width : 768px) 
		and (max-device-width : 1024px) 
		and (-webkit-min-device-pixel-ratio: 1) {
			bottom:0px;
		}
	}	
}
	
	

.section-row {
	.slick-controls {
		@include media($narrow) {
			width:33.333%;
		}
		@include media($normal) {
			width:25%;
		}
	}
}
.side-row {
	.slick-controls {
		width: 100%;
	}
}



// /* SLICK NAV ONLY
.slick-nav {
	height: 50px;
	button.slick-arrow {
		padding: 25px;
	}
	.slick-dots {
		bottom:14px;
	}	
}

aside {
	.view-slider {
		padding-bottom: 30px;
		
		.slick-nav {
		
			button.slick-arrow.slick-prev.slick-prev {
				left: 2.25rem;
			}
			button.slick-arrow.slick-prev.slick-next {
				right: 2.25rem;
			}
			.slick-dots {
				bottom: 12px;
			}
		}		
	}
}






// SLIDER VIEWS
.view-slider {
	position:relative;
	.view-content {
		margin:0;
		.slick-list {
			overflow:visible;
			.slick-track {
				background-color:$slide-text-bg-color;
				
				@include media($narrow) {
					background-color: transparent;
				}
				
				.views-row {
					margin: 0;

					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
							}

							@include media($wide) {
								left:calc(50% - 800px);
								width: auto;
							}
						}
					}
					.card-slide-description {
						@include media($narrow) {
							width:75%;
						}
						bottom:0;
						position:relative;
						background-color:$slide-text-bg-color;
						font:$slide-text-font;
						padding:17px 20px 55px 20px;
						margin:0;
						color:$slide-text-color;
						min-height:66px;
						text-align: center;

						@include media($narrow) {
							// background-color:$slide-text-bg-color;
							background-color: transparent;
							color: $slide-text-bg-color;
							position:absolute;
							padding:25px 20px;
							font: $slide-text-font-big;
							top: 0;
							width: 100%;
							text-align: center;
							color: white;
						}
					}
				}
			}
		}
		
		.card-edit-node a {
			@include button-states($button-bg, $button-text, $button-border-color);
			font-size: 0.875rem;
			font-weight: bold;
			padding: 5px 15px;
			position: absolute;
			left: 0;
			text-transform: uppercase;
			top: 0;
		}
	}
	
	// SLIDER NAVIGATION
	.view-footer {
		position:absolute;
		bottom:0;
		@include media($narrow) {
			bottom:67px;
		}
		width:100%;
		left:0;
		@include media($narrow) {
			width:300px;
			left:10px;
		}
	}
}

.section-row .view-slider .view-content .slick-list .slick-track .views-row {
	@include media($narrow) {
		margin-left: 10px;
		margin-right: 10px;
	}
}


// SLIDERS HINDERED BY SIDEBARS - image
.one-sidebar,
.sidebar-second {
	.content-main,
	.content-additional,
	.sidebar {
		.view-slider {
			.view-content .slick-list .slick-track .views-row {
				.card-slide-image img {
					left: auto;
					width: 100%;
					max-width: 100%;
				}
				.card-slide-description {
					bottom:0;
					position: relative;
					width:100%;
				}
			}
			.view-footer {
				bottom: 0;
				position:relative;
				width:100%;
				left: auto;
				
			}
		}
	}
}


// PHOTO GALLERY
.card-photos {
	position:relative;
	.photoswipe-gallery {
		margin:0;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
	}
	
	// GALLERY CONTROLS
	.view-footer {
		text-align:center;
	}
}


// SLIDER / TWITTER
.view-type-slider, .view-twitter, .view-id-twitter_hashtag {
	position:relative;
	.view-content {
		margin:0;
		position: relative;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
		
	}
	.view-footer {
		text-align:center;
	}
}

/////////////////////////////////////////////////// CUSTOM SLIDER


/*main .section-with-slider .view-slider .view-footer,
main .section-row .slick-controls {
    left: 0;
    width: 100%;
    height: 100%;
    position: initial;
}
main .postscript-first .section-row.section-with-slider .section-inner,
main .content-hero .section-row.section-with-slider .section-inner {
    overflow: visible;
}
main .slick-nav button.slick-arrow.slick-prev {
    position: absolute;
    top: 50%;
    left: -70px;
}
main .slick-nav button.slick-arrow.slick-next {
    position: absolute;
    top: 50%;
    right: -70px;
}

main .view-footer.slick-controls-wrapper {
    height: 100%;
}

main .section-row .slick-controls .slick-next.slick-arrow, .section-row .slick-controls .slick-prev.slick-arrow {
    position: absolute;
    top: 50%;
}

main .section-row .slick-controls ul.slick-dots {
    position: absolute;
    bottom: -30px;
}

main .section-row .slick-controls .slick-next.slick-arrow {
    right: -70px;
}

main .section-row .section-inner {
    overflow: visible;
}*/



/////////*************////////////

.section-row .section-inner {
	overflow: hidden;
	@media (min-width: 1740px) {
		overflow: visible;
	}
	
	/*&:after {content: ''; display:block; clear:both; }*/
}


@media (min-width: 1740px) {

	.no-sidebars main,
	section.postscript-first {


		.section-row {

			.slick-controls-wrapper,
			.slick-nav-wrapper,
			.slick-controls {
				position: initial;

				ul.slick-dots {
					position: absolute;
					bottom: -20px;
				}
			}

			.slick-controls,
			.slick-nav {

				height: auto;
				display: block;
    			margin-bottom: 40px;
				
				.slick-next,
				.slick-prev {
					padding: 25px;
					background-size: 14px auto !important;
				}
				.slick-prev {
					left: -70px;
				}
				.slick-next {
					right: -70px;
				}
			}
		}
	}
}

/*  HERO SLIDER */
	section.content-hero {
		.section-row .view-slider {
			 .view-content .slick-list .slick-track {
				.views-row {
					.card-slide-description {
						text-align: center;
						padding: 17px 20px 50px;
						@include media($narrow) {
							width:65%;
							left: 17.5%;
							padding: 17px 20px 50px;
						} 
					}
				}
			}
			
			.slick-controls-wrapper,
			.slick-nav-wrapper,
			.slick-controls {
				position: initial;

				ul.slick-dots {
					position: absolute;
					bottom: 20px;
				}
			}

			.slick-controls,
			.slick-nav {

				height: auto;
				display: block;
				
				.slick-next,
				.slick-prev {
					top: 40%;
					padding: 25px;
					background-size: 14px auto !important;
					
					@include media($narrow) {
						top: 50%;
					}
				}
				.slick-prev {
					left: 20px;
					
					@media (min-width: 1740px) {
						left: -70px;
					}
				}
				.slick-next {
					right: 20px;
					
					@media (min-width: 1740px) {
						right: -70px;
					}
				}
			}
		}
	}