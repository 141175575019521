.node-type-person {
	
	h1 {
		display: inline-block;
	}
	.node-person-title {
		@include clearfix;
	}
	
	.node-inner {
		overflow: hidden;
		padding: $vert-space*1.5 $horz-space*1.5;
		background: white;
		@include media($narrow) {
			@include span-columns(6);
		}
		:last-child {
			margin-bottom: 0;
		}
	}
	
	.card-suffix {
		color: $trolley_grey;
		display: inline-block;
		margin: 0 0 10px;
	}
	
	.card-profile-details {
		text-align: center;
		@include media($narrow) {
			@include span-columns(3);
			margin-bottom: $vert-space;
			margin-right: 0;
		}
		.card-photo img {
			max-width: 100%;
			width: 400px;
		}		
		.card-links {
			background-color: $card-light-bg-color;
			padding: 20px 20px 5px;
			text-align: left;
			display: none;
			@include media($narrow) {
				display: block;
			}
		}
	}
	
	.card-profile-links {
		@include media($narrow) {
			@include span-columns(3);
			margin-right: 0;
		}
	}
	
	.view-display-id-person_blogs.section-inner {
		max-width: 1600px;
		margin-left: auto;
		margin-right: auto;
		padding:0 20px;
   		overflow: hidden;
		.view-header h2 {
			font:500 3.125rem/1.08 $title-font-family;
			margin-bottom: 40px;
			text-align: left;
			clear: both;
			@include media($normal) {
			//font-size:3.125rem;
			//line-height: 1.33333333333333;
			text-align: center;
		  }
		  .card-title {
			background: $title-prefix no-repeat 0 100% / 38px 65px,
									$title-suffix no-repeat 100% 0 / 38px 65px;
			color:$primary;
			display: inline-block;
			padding: 18px 25px;
		  }
		}
		a.card {
			background-color:white;

			h3 {
				color:$sea_blue;
			}

			&:hover h3 {
				color:$mango_tango;
			}
		}
	}
	.card-links {
		overflow:hidden;
	}
	
	.card-links + .user-profile-button {
		margin-top:20px;
	}
	
	@media (max-width:719px) {
		.card-profile-links {
			margin-top:20px;
		}
	}
}

@include media($narrow) {
	.section-row.row-max-1.view-type-slider {
		.card-photo {
			@include span-columns(2);
			
			margin-bottom: 0; 
			margin-right: 0;
		}
	}
}

.node-person,
.page-user {
  .card-links ul li,
  .card-links {
    a /* Unidentified */ {
			float: left;
			height: 60px;
      line-height: 1.25;
      margin:0 1px 1px;
      padding:$vert-space $horz-space;
			text-indent: -9999px;
			width: 80px;
     
       &:hover {
         background: $ico-web-over no-repeat 50% 50% / auto 20px $sea_blue;
         color:$action-hover;
       }
    }
  }
}

.node-person .card-profile-links,
.soc-accelerators,
.page-user .card-profile-links {
  .card-links ul li,
  .card-links {
    a /* Unidentified */ {
      background:$ico-web no-repeat 50% 50% / auto 20px $platinum_blue;
			box-sizing: border-box;
      color:$action-default;
      display: block;
      overflow: hidden;
      word-wrap:break-word;
     
       &:hover {
         background: $ico-web-over no-repeat 50% 50% / auto 20px $sea_blue;
         color:$action-hover;
       }
    }
  }

  .card-links /* Institutional profile */ {
    .institution a,
    a[href*=".ac.uk"],
    a[href*=".edu"] {
      background: $ico-institution no-repeat 50% 50% / auto 22px $platinum_blue;
      
        &:hover {
          background: $ico-institution-over no-repeat 50% 50% / auto 22px $sea_blue;
        }
    }
  }

		h2 {
			background: white;
			font-size: 1.5rem;
			font-weight: 500;
    	line-height: 1.333333333333333;
			padding: $vert-space $horz-space;
			margin: 0;
		}
	
  .card-links /* Research Gate */ {
    .research-gate a,
    a[href*="researchgate.net"],
    a[href*="researchgate.com"],
    a[href^="https://www.researchgate.net"],
    a[href^="http://www.researchgate.net"],
    a[href^="https://researchgate.net"],
    a[href^="http://researchgate.net"] {
          background: $ico-researchgate no-repeat 50% 50% / auto 20px $platinum_blue;
      
        &:hover {
          background: $ico-researchgate-over no-repeat 50% 50% / auto 20px $sea_blue;
        }
    }
  }
  
  .card-links /* Email */ {
    .email a,
    a[href^="mailto:"],
    a[href*="@"] {
      background: $ico-email no-repeat 50% 50% / auto 18px $platinum_blue;
      
      &:hover {
        background: $ico-email-over no-repeat 50% 50% / auto 18px $sea_blue;
      }
    }
  }
  
  .card-links /* Google Scholar */ {
    .g-scholar a,
    a[href*="scholar.google"] {
    background: $ico-gscholar no-repeat 50% 50% / auto 20px $platinum_blue;
    
      &:hover {
        background: $ico-gscholar-over no-repeat 50% 50% / auto 20px $sea_blue;
      }
    }
  }
  
  .card-links /* linkedin */ {
    .linkedin a,
    a[href*="linkedin.com"],
    a[href^="https://www.linkedin.com"],
    a[href^="http://www.linkedin.com"],
    a[href^="https://linkedin.com"],
    a[href^="http://linkedin.com"] {
      background: $ico-linkedin no-repeat scroll 50% 50% / auto 20px $platinum_blue;
      
      &:hover {
        background:$ico-linkedin-over no-repeat scroll 50% 50% / auto 20px $sea_blue;
      }
    }  
  }
  
  .card-links /* twitter */ {
    .twitter a,
    a[href*="twitter.com"],
    a[href^="https://www.twitter.com"],
    a[href^="http://www.twitter.com"],
    a[href^="https://twitter.com"],
    a[href^="http://twitter.com"] {
      background: $ico-twitter no-repeat scroll 50% 50% / auto 20px $platinum_blue;
      
      &:hover {
        background: $ico-twitter-over no-repeat scroll 50% 50% / auto 20px $sea_blue;
      }
    }
  }
  
  .card-links /* facebook */ {
    .facebook a,
    a[href*="facebook.com"],
    a[href^="https://www.facebook.com"],
    a[href^="http://www.facebook.com"],
    a[href^="https://facebook.com"],
    a[href^="http://facebook.com"] {
      background: $ico-facebook no-repeat scroll 50% 50% / auto 20px $platinum_blue;
    
      &:hover {
        background: $ico-facebook-over no-repeat scroll 50% 50% / auto 20px $sea_blue;
      }
    }
  }
  
  .card-links /* google+ */ {
    .g-plus a,
    a[href*="plus.google.com"],
    a[href^="https://www.plus.google.com"],
    a[href^="http://www.plus.google.com"],
    a[href^="https://plus.google.com"],
    a[href^="http://plus.google.com"] {
      background:$ico-gplus no-repeat scroll 50% 50% / auto 20px $platinum_blue;
      
      &:hover {
        background: $ico-gplus-over no-repeat scroll 50% 50% / auto 20px $sea_blue;
      }
    }
  }
	
	 .card-links /* google(+) */ {
		.google a {
      background:$ico-gplus no-repeat scroll 50% 50% / auto 20px $platinum_blue;
      
      &:hover {
        background: $ico-gplus-over no-repeat scroll 50% 50% / auto 20px $sea_blue;
      }
    }
  }

  .card-links /* orcid */ {
    .orcid a,
    a[href*="orcid.com"],
    a[href^="https://www.orcid.com"],
    a[href^="http://www.orcid.com"],
    a[href^="https://orcid.com"],
    a[href^="http://orcid.com"] {
      background:$ico-orcid no-repeat scroll 50% 50% / auto 18px $platinum_blue;
      
      &:hover {
        background: $ico-orcid-over no-repeat scroll 50% 50% / auto 18px $sea_blue;
      }
    }
  }
  
  .card-links /* YouTube */ {
    .youtube a,
    a[href*="youtube.com"],
    a[href^="https://www.youtube.com"],
    a[href^="http://www.youtube.com"],
    a[href^="https://youtube.com"],
    a[href^="http://youtube.com"] {
      background:$ico-youtube no-repeat scroll 50% 50% / auto 17px $platinum_blue;
      
      &:hover {
        background: $ico-youtube-over no-repeat scroll 50% 50% / auto 17px $sea_blue;
      }
    }
  }
  
  .card-links /* flickr */ {
    .flickr a,
    a[href*="flickr.com"],
    a[href^="https://www.flickr.com"],
    a[href^="http://www.flickr.com"],
    a[href^="https://flickr.com"],
    a[href^="http://flickr.com"] {
      background:$ico-flickr no-repeat scroll 50% 50% / auto 10px $platinum_blue;
      
      &:hover {
        background: $ico-flickr-over no-repeat scroll 50% 50% / auto 10px $sea_blue;
      }
    }
  }
}