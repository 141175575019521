.node-form {
  input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea, select[multiple=multiple], textarea {
    border-radius:0;
  }
  .filter-wrapper {
    padding:0;
    border:none;
    .filter-help, .filter-guidelines {
      display:none;
    }
    .form-type-select {
      padding-left:0;
    }
    display:none;
  }
  .field-type-text-long {
    .description {
      display:none;
    }
  }
  .field-type-file {
    .description {
      display:block;
    }
  }
  .field-name-field-video {
    .form-wrapper {
      border:none;
      padding:0;
      margin:0;
      legend {
        display:none;
      }
    }
  }
}
