.item-list  ul.pager:not(.pager-load-more) {
	//border-top: $primary-border;
	position: relative;
	@media screen and (max-width: $narrow) {
		font-size: 0.750em;
	}
	a {
		color: $secondary;
		font-weight: 600;
		padding:0.25em 1em;
		border-radius: 40px;
		
		&:hover {
			background:$action-hover;
			color:#FFFFFF;
		}
	}

	
	li.pager-next {
		float: right;
		position: absolute;
		right: 55px;
		@media screen and (max-width: $narrow) {
			right: 35px;
		}
		a {
			//background: $arrow-right;
			background-position: 100% 50%;
			background-size: 8px;
		}
	}
	li.pager-last {
		float: right;
		a {
			//background: $arrow-right-last;
			background-position: 100% 50%;
			background-size: 14px;
		}
	}
	li.pager-previous {
		float: left;
		position: absolute;
		left: 55px;
		@media screen and (max-width: $narrow) {
			left: 35px;
		}
		a {
			//background: $arrow-left;
			background-position: 0% 50%;
			background-size: 8px;
		}
	}
	li.pager-first {
		float: left;
		a {
			//background: $arrow-left-first;
			background-position: 0% 50%;
			background-size: 14px;
		}
	}
	li.pager-item {
		display: inline-block;
		margin: 0 3px;
	}
	li.pager-current {
		background: $action-default;
		color:white;
		padding:0.25em 1em;
		border-radius: 40px;
		//border-radius:50%;
		//border-top: 3px solid;
		//border-bottom: 3px solid;
	}
	

	
	
	li.pager-first,
	li.pager-previous,
	li.pager-next,
	li.pager-last {
		a {
			background-color: $action-hover;
			color: #FFFFFF;
			display: block;
			font-family: $title-font-family;
			&:hover {
				background-color: $medium_vermilion;
				color: #FFFFFF;
			}
		}
	}	
}

// LOAD MORE PAGER

ul.pager.pager-load-more {
	margin:auto;
}


.item-list ul.pager.pager-load-more {

	a {
		color: white;
		text-transform:uppercase;
		font:500 0.875em/1 $title-font-family;	
		border-radius:50%;
		display:inline-block;
		background:$mango_tango $arrow-button-down  no-repeat scroll 9px 18px / 55px auto;
		height: 54px;
		padding: 13px 0px;
		width: 54px;
		margin-bottom:40px;

		&:hover {
			color:white;
			background:$medium_vermilion $arrow-button-down  no-repeat scroll 9px 18px / 55px auto;
		}
	}
}