.page-user {
	h1 {
		margin-bottom: 30px;
		text-align: left;
	}
	
  // User profile info
  .view-display-id-profile {
    .expanded-profile {
      display: none;
    }
    .card-picture ,
		.card-image-URL,
    .card-user-picture {
      @include media($narrow) {
        @include span-columns(4);
        margin-right: 0;
      }
      .profile-picture {
        line-height: 0;
        img {
          max-width: 100%;
          width: 400px;
        }
      }   
    }
		.profile-details-wrapper {
			overflow: hidden;
		}
   .profile-details {
      @include media($narrow) {
/*        @include span-columns(8);
        margin-right: 0;*/
      }
      background: #FFFFFF;
      padding: 30px;
      border-radius: 0 0 20px 0;
		 margin-bottom: $vert-space;
      h1#page-title {
        text-align: left;
      }
      .profile-job {
        color: $trolley_grey;
        font-size: 28px;
        font-weight: 500;
        line-height: 33px;
        margin-bottom: 20px;
				
				.profile-jobtitle + .profile-organisation {
					&:before {
						content: " | ";
						display: inline-block;
						margin-right: 6px;
					}
				}
      }
    }
  }
	.user-content-buttons {
		a {
			border-radius: 0;
			//flex-grow:1;
			margin: 0 0 $vert-space;
			text-transform:uppercase;
			font:600 1.125rem/1.16666666666667 $base-font-family;
			padding:12px 10px;
			text-align:center;
			
			@media (min-width:540px) {
				margin:0 12px $vert-space  0;
				width: calc(100%/3 - 12px);
			}
			
			@media (min-width:$narrow) and (max-width:959px) {
				min-width: calc(33% - 16px);
				width: auto;
			}

			
			&:last-child { margin-right: 0; }

			&.button-add:before {
				background-size:contain;
				height:22px;
				vertical-align: top;
				width:22px;
			}
		}
	}
	.card-image-URL  + .profile-details-wrapper {
		.user-content-buttons {
				@include media($narrow) {
					margin-left: 20px;
				}
			a {
				@media (min-width:$narrow) and (max-width:959px)  {
					width: calc(100%/2 - 10px);
					&:nth-child(even) {
						margin-right: 0;
					}
				}
				@media (min-width:$narrow) and (max-width:1199px)  {
					font-size: 0.875rem;
					&.button-add:before {
						height: 17px;
						width: 17px;
						margin-right: 5px;
					}
				}
			}
		}
	}	
	
  aside#sidebar-second {
    h2 {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.333333333333333;
    }

		.profile-link {
			margin-bottom: 10px;
			
			&:last-child {
				margin-bottom: 0;
			}
		}
		
		.card-profile-links {
			margin-bottom: $vert-space;
		}

		.profile-label {
			font-family: $title-font-family;
			font-size: 1.5rem;
			font-weight: 500;
			line-height: 32px;
			margin-bottom: 10px;
		}
		.profile-roles {
			text-transform: capitalize;
		}
		
		.card-street-address:before {
			content: "Address";
			display: block;
			font: 500 1.125rem/1.3 $title-font-family;
			margin: 15px 0 5px;
		}
		
		.m-only {
			color: $independence;
			margin: 5px 0 15px;
		}
  }
}

.page-user.not-logged-in,
.page-user-register,
.page-user-forms.not-logged-in {
  main .content-main {
    background: #ffffff;
    padding: $vert-space*2 $horz-space*2;
    margin-bottom: $horz-space;
  }
  .login-button-block,
  .register-button-block {
    background: #ffffff;
    padding: $vert-space $horz-space;
    border-radius: 0 0 20px 0;
  }
}
.page-user-.not-logged-in main .content-main {
  background: transparent;
  padding: 0;
  margin: 0;
}

.page-user-forms.not-logged-in {
	#user-login-form {
		.item-list {
			float: right;
			li { 
				a {
					@include button-states($trolley_grey, white, $trolley_grey);
					border-radius: 40px;
					cursor: pointer;
					font: 600 1rem $base-font-family;
					padding: 0.75em 1em;
					text-decoration: none;
					text-transform: uppercase;
					vertical-align: middle;
					transition: background-color 300ms linear 0s;
				}
				&.first { display: none; }
			}
		}

	}
}

.page-user.not-logged-in {
	#user-login {
		a.button-password {
			@include button-states($trolley_grey, white, $trolley_grey);
			border-radius: 40px;
			cursor: pointer;
			font: 600 1rem $base-font-family;
			padding: 0.75em 1em;
			text-decoration: none;
			text-transform: uppercase;
			vertical-align: middle;
			transition: background-color 300ms linear 0s;
			float: right;
		}
	}
}
