// .page-search-node {
.page-search {
	section#content {
		background: none;
		.search-advanced {
			margin-top: $vert-space;
		}
		.search-result {
			padding: $vert-space $horz-space;
		}
	}
  .view-filters {
    margin: 0 20px;
    .views-exposed-fields {
      float: left;
    }
    .views-submit-button {
      margin: 20px 0 0 20px;
    }
  }
}
