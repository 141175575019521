.wrapper-region-header {

	a[href="/cart"] {
		display: none;
		margin-right: 10px;
		text-indent: -999em;
		background: $icon-basket no-repeat scroll 50% 50% $button-bg !important;
		border-radius: 50% !important;
		width: $card-links-width;
		height: $card-links-height;
		margin:0;
		outline: none;

		&:hover {
			opacity: $card-links-hover-opacity;
			border-radius: 50%;
			background: $icon-basket no-repeat scroll 50% 50% $button-hover-bg !important;
		}
	}
}

.logged-in {
	.wrapper-region-header {
		a[href="/cart"] {
			display: inline-block;
		}
	}
	
	// dissabled by pipe, enabled if it is necessary 
	// #site-header {
	// 	.block-menu {
	// 		ul.menu {
	// 			margin: 0;
	// 		}
	// 	}
	// }
}

.commerce-line-item-actions {
	input {
		margin: 0 0 0 0.5rem;
	}
}