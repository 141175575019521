/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;
		
		.views-row {
			background: white;
			padding: $vert-space/2 $horz-space;
			margin: 0 0 1px;
		}
		
	.card-qa-answer-wrapper {
		display: none;
	}
	.card-qa-answer {
		padding: 10px;	
		box-sizing: border-box;
 
		:last-child {
			margin-bottom: 0;
		}
	}
	
	.card-qa-question {
		text-decoration: none;
		/*color: $primary;*/
		padding: 10px 0 10px 30px;
		line-height: 20px;
		display: block;
		/*border: 1px solid #d0d0d0;*/
		margin-bottom: -1px;
		cursor: pointer;
		box-sizing: border-box;
		background: $ico-expand no-repeat scroll 0 50% / 16px 16px transparent;
		font: 500 1.25rem/1.41666666666667 $title-font-family;
		
		&:hover, &:focus {
			color: $action-default;
		}

		&.active {
			background: $ico-collapse no-repeat scroll 0 50% / 16px 16px transparent;
		}

	}
	
	/*.ico-collapse, .ico-expand {
		width: 20px;
		height: 20px;
		float:left;
		margin-right: 20px;
	}

	.ico-collapse {
		
	}
	.ico-expand {
		
	}*/

}