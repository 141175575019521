.node-type-resource {
  .content-title {
    padding-bottom: 0;
  }
	.card-subtitle {
		color: $trolley_grey;
		font: 600 1.75rem/1.178571428571429 $base-font-family;
		margin-bottom:20px;
	}
  h1 {
    text-align: left;
    // margin-bottom: 0;
  }
  // .card-body p {
  //   font-size: 1.5rem;
  //   border-bottom: 1px solid rgba(117, 150, 174, 0.4);
  //   padding-bottom: 8px;
  // }

  // Re-order RHS on mobile
  @media (max-width: $narrow) {
    #sidebar-second { order: 1; }
    #content { order: 2; }
  }
  // RELATED AND RESOURCE SERIES VIEWS
  .content-additional {
    a.card:hover .card-title-field {
      color: $mango_tango;
    }
    section {
      margin-bottom: 40px;
    }
    h2 {
      font:500 3.125rem/1.08 $title-font-family;
      margin-bottom: 40px;
      text-align: left;
      clear: both;
      @include media($normal) {
        //font-size:3.125rem;
        //line-height: 1.33333333333333;
        text-align: center;
      }
      .view-title {
        background: $title-prefix no-repeat 0 100% / 38px 65px,
                    $title-suffix no-repeat 100% 0 / 38px 65px;
        color:$primary;
        display: inline-block;
        padding: 18px 25px;
      }
    }
    .card {
      .card-image-outer {
        float: left;
        max-width: 340px;
        position: relative;
      }
      .card-title-field {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        color: $sea_blue;
      }
      .card-text {
        background: #FFFFFF;
        border-radius: 0 0 20px 0;
        color: $secondary;
        //clear: none;
      }
    }
  }
}
body.node-type-resource.one-sidebar.sidebar-second main aside#sidebar-second {
  margin-top: 0;
}
.m-only {
  border: 1px solid rgba(128,126,122,0.4);
  text-transform: uppercase;
  padding: 6px 10px;
  display: inline-block;
  color: rgba(64,82,95,0.6);
  margin-top: 20px;
}

// RESOURCE DETAILS
.res-details {
  background: white;
  padding: 20px;
  display: inline-block;
  width: 100%;
  font-size: 1.125rem;
  margin: 0 0 20px;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
  .m-only {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .res-label {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 8px;
    font-family: $title-font-family;
  }
  .card-views-conditional {
    width: 100%;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 19px;
  }
  .card-date,
  .card-resource-type,
  .card-members,
  .card-authors {
    font-size: 1.125rem;
  }
  .card-date {
    border-bottom: none;
    margin-bottom: 15px;
    padding-bottom: 0;
    line-height: inherit;
  }
  .card-link a {
    display:inline-block;
    overflow:hidden;
    text-overflow:ellipsis;
    max-width:100%;
    white-space:nowrap;
  }
}
.view-display-id-av_details {
	.res-details > div {
  	display: inline-block;
  	width: 100%;
	}
	.res-details > div:nth-last-child(2) {
  		margin-bottom: 30px;
	}
}
.res-details > div {
  display: inline-block;
  width: 48%;
  margin-bottom: 30px;
	vertical-align:top;
  @media (max-width: 719px) {
    clear: left;
	display:block;
	width:100%;
  }
}
body:not(.iasc-accountability-and-inclusion-resources-portal) {
  .res-details .card-res-use-hum-prog-cycle {
    display: none;
  }
}
.res-details > div:nth-last-child(1),
.res-details > div:nth-last-child(2) {
  margin-bottom: 0;
}
.node-content .res-details .card-date {
  border-bottom: none;
}

body.two-sidebars.theme-node-leaf {
  main aside#sidebar-first {
    margin-top: 0;
  }
}

.node-form.node-resource-form {
  .field-group-fieldset.group-import {
    display:none !important;
    * {
      display:none !important;
    }
  }
}
