$filter-width:260px;

.ajax-progress, ajax-progress-throbber, .flag-throbber {
  display: none;
}

body {
  &.ajax-processing {
    .view {
      * {
        cursor: wait;
      }
    }
  }
}

.view-results {
  color: $trolley_grey;
  font-size: 1.375rem;
  font-weight: bold;
  line-height: 1.13636363636364;
}

.view-filters-toggle {
  margin-bottom: 30px;
  vertical-align: middle;

  span {
    background-color: white;
    box-shadow: $box-shadow;
    cursor: pointer;
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 24px;
    padding: 9px 30px;
    text-transform: uppercase;

    &:after {
      background: $plus no-repeat 50% 50% / 12px 12px $mango_tango; //auto
      border-radius: 50%;
      content: " ";
      display: inline-block;
      height: 24px;
      margin: 0 0 -5px 20px;
      width: 24px;
    }

    &:hover {
      &:after {
        background-color: $sea_blue;
      }
    }
  }
}

.view-filters-toggle + form {
  .views-exposed-fields {
    background-color: white;
    box-shadow: $box-shadow;
    display: none;
    height: 0;
    margin: -30px 0 30px;
    padding: 30px;
    transition: all 2s ease;

    .views-widget-filter-keys {
      float: none;
      padding-bottom: 40px;
      @media (min-width: 540px) {
        padding-bottom: 0;
        input.form-text {
          width: calc(100% - 168px);
        }
      }
    }
  }

  input[type="submit"] {
    display: none;
  }

  .views-submit-button {
    float: none;
    padding: 0;
  }
}

.expanded .views-exposed-widgets .views-widget-filter-secondary {
  .view-search-resources & {
    opacity: 1;
    // height:866px;
    height: auto;
    @media(min-width: 1040px) {
      height: 446px;
    }
  }

  .view-events & {
    opacity: 1;
    height: auto;
    @media(min-width: 1040px) {
      height: 282px;
    }
  }

  .view-blog & {
    opacity: 1;
    height: auto;
    @media(min-width: 1040px) {
      height: 194px;
    }
  }
}

.view-filters {
  position: relative;

  .views-exposed-widgets .views-widget-filter-secondary {
    display: block !important;
    opacity: 0;
    height: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  }

  &.expanded {
    .views-exposed-widgets .views-widget-filter-secondary {
      padding-top: 20px !important;
      opacity: 1;
      height: auto;
      padding-bottom: 20px !important;
    }

    form {
      .views-exposed-fields {
        display: block;
        height: auto;
      }

      input[type="submit"] {
        display: block;
      }
    }

    .view-filters-toggle {
      span {
        box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.1);
        padding-bottom: 25px;

        &:after {
          background-image: $minus;
          background-size: 12px auto;
        }
      }
    }
  }

  label {
    color: $primary;
    font-size: 1.3em;
    font-weight: 500;
    line-height: 1.16666666666667;
    margin-bottom: 4px;
  }
}


.views-exposed-form {
  .views-widget-filter-keys {
    label {
      height: 0;
      margin: 0;
      padding: 0;
      visibility: hidden;
    }

    input.form-text {
      border-radius: 40px;
      height: 39px;
      font-size: 0.875rem;
      padding: 0 22px 0 15px;
    }
  }

  .views-widget-filter-search_api_views_fulltext {
    input.form-text {
      border-radius: 40px;
      height: 39px;
      font-size: 0.875rem;
      padding: 0 22px 0 15px;
    }
  }

  .views-widget-filter-secondary {
    //border-top: $primary-border;
    float: none;
    margin: 30px -30px 0;
    padding: 20px 30px;

    fieldset {
      border: 0;
      padding: 0;
      margin: 0;
      box-shadow: none;
    }
  }
}


.chosen-container-multi .chosen-choices,
.chosen-container-single .chosen-choices {
  background: $arrow-button-down-alt no-repeat 100% 50% /35px 35px $platinum;
  border: none;
  border-radius: 40px 40px 40px 40px;
  box-sizing: border-box;
  min-height: 35px;
  padding: 4px 40px 4px 15px;

  li.search-field {
    //float: none;
    input[type="text"] {
      color: $primary !important;
      font: 1rem/1.1875 $base-font-family;
      //padding: 0 20px;
      //width: 100% !important;
    }
  }

  li.search-choice {
    margin: 3px 0px 3px 7px;
    padding: 5px 23px 5px 8px;
    border: none;
    background: white;
    box-shadow: none;
    color: $primary;
    line-height: 13px;
    cursor: default;
    font-size: 16px;
    font: 1rem/1 $base-font-family;

    .search-choice-close {
      top: 7px;
    }
  }
}

.chosen-container-single .chosen-single,
.chosen-container-active.chosen-with-drop .chosen-single {
  background: $arrow-button-down-alt no-repeat 100% 50% /35px 35px $platinum;
  border: none;
  border-radius: 40px;
  font-size: 16px;
  height: 35px;
  padding: 6px 20px;
}

.chosen-container {
  .chosen-drop {
    border: $primary-border;
    border-top: 0;
    background: #fff;
    box-shadow: $box-shadow;
    margin: 0 0 0 10px;
    width: calc(100% - 10px);
    font: 1rem/1.1875 $base-font-family;
  }

  .chosen-results {
    color: $primary;

    li.active-result {
      padding: 10px;

      &.highlighted {
        background: $sea_blue;
      }
    }
  }
}

.layout-trigger {
  background: white;
  box-shadow: $box-shadow;
  color: $primary;
  float: right;
  margin-right: 3px;
  padding: 3px 4px;
  position: relative;
  z-index: 1;
  @media(max-width: 1100px) {
    display: none;
  }

  .trigger-list,
  .trigger-grid {
    cursor: pointer;
    display: inline-block;
    height: 36px;
    text-indent: -9999px;
    width: 40px;
  }

  .trigger-list {
    background: $trigger-list no-repeat 50% 50% / 29px auto transparent;

    &:hover {
      background: $trigger-list-hover no-repeat 50% 50% / 29px auto transparent;
    }

    &.active {
      background: $trigger-list-active no-repeat 50% 50% / 29px auto $independence;
    }
  }

  .trigger-grid {
    background: $trigger-grid no-repeat 50% 50% / 30px auto transparent;

    &:hover {
      background: $trigger-grid-hover no-repeat 50% 50% / 30px auto transparent;
    }

    &.active {
      background: $trigger-grid-active no-repeat 50% 50% / 30px auto $independence;
    }
  }
}

#edit-bef-sort-options,
.views-exposed-form .views-widget-sort-by,
.views-exposed-form .views-widget-sort-order,
.views-exposed-form .form-item-sort-bef-combine,
.views-exposed-form #edit-dates-wrapper,
.view-header .form-item-items-per-page,
.view-header .form-item-sort-bef-combine {
  border: none;
  margin: 0 118px 0 0;
  float: right;
  padding: 0;

  legend {
    display: none;
  }

  label,
  select {
    display: inline-block;
  }

  label {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.22222222222222;
    margin-right: 8px;
  }

  .form-select,
  .form-item-sort-bef-combine,
  .views-widget-filter-dates {
    background-clip: padding-box;
    background: $arrow-button-down-alt-2 no-repeat calc(100% - 10px) 50% / 24px 24px white;
    border-radius: 0;
    color: $primary;
    font: 1rem/1.1875 $base-font-family;
    margin: 0 0 0 5px;
    height: 42px;
    padding: 10px 50px 10px 10px;
    cursor: pointer;
  }
}

.page-number select {
  background-clip: padding-box;
  background: $arrow-button-down-alt-2 no-repeat calc(100% - 10px) 50% / 24px 24px white;
  border-radius: 0;
  color: $primary;
  font: 1rem/1.1875 $base-font-family;
  margin: 0 0 0 5px;
  height: 42px;
  padding: 10px 50px 10px 10px;
  cursor: pointer;
}

.view-header .form-item-items-per-page,
.view-header .form-item-sort-bef-combine {
  float: none;
}

.views-exposed-form .form-item-sort-bef-combine {
  margin: 0 105px 22px 0;
}

.view-id-search_resources .views-exposed-form .form-item-sort-bef-combine {
  margin: 0 25px 0 0;
  @media(max-width: 539px) {
    margin: 0;
  }
}

.view-blog,
.view-search-resources,
.view-news,
.view-id-members,
.view-jobs,
.view-events {
  .view-filters {
    .views-submit-button,
    .views-reset-button {
      input[type="submit"] {
        height: 39px;
        position: absolute;
        padding: 7px 20px;
        top: 130px;
        @media (min-width: 540px) {
          top: 70px;
        }
      }
    }

    .views-reset-button input[type="submit"] {
      right: 30px;
    }

    .views-submit-button input[type="submit"] {
      right: 140px;
    }

    #edit-secondary-wrapper {
      background-color: white;

      fieldset {
        legend {
          visibility: hidden;
          height: 0;
        }

        .fieldset-wrapper {
          &:before {
            content: "Filter by";
            display: block;
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.2;
            text-transform: uppercase;
            margin-bottom: 20px;
          }
        }

        .form-type-select {
          .chosen-container {
            display: block;
            width: 100% !important;
          }
        }
      }
    }

  }

  .view-header {
    // margin: 30px 0 0;
    @include clearfix;

    .view-results {
      float: left;
      margin-top: -66px;
    }

    .layout-trigger {
      float: right;
      margin-top: -72px;
      margin-bottom: 30px;
      @media(max-width: 539px) {
        display: none;
      }
    }
  }

  @media(max-width: 30em) {
    .view-header {
      margin-bottom: 10px;

      .view-results {
        float: none;
      }
    }
  }
}

.view-blog,
.view-news,
.view-id-members,
.view-jobs,
.view-events {
  @media(min-width: 720px) {
    .bef-secondary-options {
      display: flex;
      flex-wrap: wrap;
    }
  }
  @media(min-width: 540px) {
    .views-exposed-fields .form-type-select {
      width: 100%;
      padding: 0.5em 1em 0 0;

      .chosen-container {
        display: block;
        width: 100% !important;
      }
    }
  }
  @media(min-width: 720px) {
    .views-exposed-fields .form-type-select {
      width: calc(100% * (1 / 2) - 10px - 1px);
    }
  }
  @media(min-width: 1040px) {
    .views-exposed-fields .form-type-select {
      width: calc(100% * (1 / 4) - 10px - 1px);
    }
  }
}

.view-events {
  .view-header {
    margin: 60px 0 0;

    .view-results {
      float: left;
    }
  }
}

.collapsible-filters {
  .bef-secondary-options .form-item {
    margin-bottom: 20px;
    margin-right: 0;
  }
}

// HELP LIBRARY SPECIFIC STYLES
.view-search-resources,
.view-resources-search {
  .view-filters {
    .views-exposed-form .views-exposed-widget {
      padding: 0;
    }

    .views-widget-filter-search_api_views_fulltext {
      float: none;
      width: 100%;
      padding-bottom: 40px;
      @media (min-width: 540px) {
        padding-bottom: 0;
      }
    }

    .view-results {
      display: block;
      color: $trolley_grey;
      font-size: 1.375rem;
      font-weight: bold;
      line-height: 1.13636363636364;
      margin: 30px 0;
      padding-top: 9px;

      @media(max-width: 539px) {
        margin-top: 100px;
      }
    }

    .views-exposed-widgets {
      display: flex;
      flex-flow: row wrap;

      #edit-keywords-wrapper {
        order: 1;
        flex: 1 100%;
      }

      .views-submit-button {
        order: 2;
      }

      .views-reset-button {
        order: 3;
      }

      #help-library-summary {
        order: 4;
        flex: 1 100%;
        padding: 0 20px;
        margin-bottom:20px;
        background: white;

        ul {
          list-style: none;

          &:before {
            content: attr(aria-label);
            font-weight: bold;
            margin-left: -15px;
          }

          li {
            .remove {
              font-size: 21px;
              font-weight: 500;
              color: #DF573B;
              cursor: pointer;
              margin-right: 0;
              width: 20px;
              padding: 0 5px;
            }
          }
        }
      }

      .expand-trigger {
        order: 5;
        flex: 2 100%;
      }

      #edit-secondary-wrapper {
        order: 5;
        flex: 3 100%;
      }

      #edit-keywords-wrapper {
        padding: 0;

        label {
          margin-bottom: 0;
          font-size: 20px;
        }

        .views-widget {
          background: #FFFFFF;
          //box-shadow: 0px 0px 6px rgba(0,0,0,0.1);
          .form-item {
            padding: 30px;

            @media(max-width: 539px) {
              padding-bottom: 80px;
            }
          }
        }
      }

      .expand-trigger {
        width: 100%;
        display: flex;
        background-color: #F0F0F0;
        padding: 30px;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin-top: -10px;
        border-top: 1px solid #E6E6E6;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        z-index: 1;

        &:hover {
          cursor: pointer;
        }

        &:after {
          content: "";
          display: block;
          height: 24px;
          width: 24px;
          background: $mango_tango $plus no-repeat scroll;
          margin-left: 20px;
          border-radius: 50%;
          background-size: 12px;
          background-position: 50% 50%;
          @media (max-width: 379px) {
            width: 34px;
          }
        }
      }
    }

    &.expanded {
      #edit-secondary-wrapper {
        display: block !important;
      }

      .expand-trigger:after {
        background: $mango_tango $minus no-repeat scroll;
        background-size: 12px;
        background-position: 50%;
      }
    }

    .views-exposed-form {
      .views-widget-filter-secondary {
        //border-top: 1px solid rgba(44,63,78,0.1);
        float: none;
        margin: 0;
        padding: 20px 30px;
      }

      .views-exposed-widget .form-submit {
        margin-top: 1px;
      }

      .fieldset-wrapper {
        .search-filter {
          margin-bottom: 20px;
          border-radius: 0;

        }
      }
    }

    #edit-secondary-wrapper {
      .bef-secondary-options {
        .chosen-container-single .chosen-single div b {
          display: none;
        }

        .form-type-select {
          margin-top: 10px;
          margin-bottom: 20px;
          @media (min-width: 1040px) {
            @include span-columns(3);
          }
          @media (max-width: 1039px) {
            @include span-columns(6);
            width: 47%;
          }
          @media (max-width: 540px) {
            @include span-columns(12);
          }

          .chosen-container {
            display: block;
            width: 100% !important;
          }

          &.form-item-country {
            clear: left;
            @media (max-width: 1039px) {
              clear: none;
            }
          }
        }

        .container-inline-date {
          clear: none;
          @include span-columns(3);
          @media (max-width: 719px) {
            @include span-columns(6);
            width: 47.5%;
          }
          @media (max-width: 540px) {
            @include span-columns(12);
          }

          &.container-date-from {
            clear: left;
            @media (max-width: 719px) {
              @include span-columns(6);
              width: 47.5%;
            }
            @media (max-width: 540px) {
              @include span-columns(12);
            }
          }

          .form-item {
            width: 100%;
          }

          .form-type-date-popup {
            .form-item-from-date label,
            .form-item-from-date .description,
            .form-item-to-date label,
            .form-item-to-date .description,
            fieldset.collapsible .fieldset-legend {
              display: none;
            }

            #edit-from,
            #edit-to {
              width: 100%;
            }

            input#edit-from-datepicker-popup-0,
            input#edit-to-datepicker-popup-0 {
              background: $icon-calender no-repeat 100% 50% /35px 35px $platinum;
              border: none;
              width: 100%;
              height: 35px;
            }
          }
        }

        .form-item.form-type-select.form-item-int {
          display: none;
        }

        .form-item {
          select {
            display: none;
          }
        }

        .bef-checkboxes {
          margin-top: 10px;

          .form-type-bef-checkbox {
            display: inline-block;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

// NEW HELP LIBRARY SPECIFIC STYLES
.view-resources-search {
  .feed-icon {
    display: block;
    a:after {
      content: "Download CSV";
      background: $mango_tango;
      border-radius: 40px;
      color: white;
      font: 600 16px $base-font-family;
      padding: 0.75em 1em;
      text-transform: uppercase;
    }
    a {
      &:hover:after {
        background: $medium_vermilion;
      }
      img {
        display: none;
      }
    }
  }
  .view-content ul {
    padding-left: 0;
  }
  .view-results {
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .layout-trigger {
      margin-right: 30px;
      z-index: 99;
    }
    @media(min-width: 1110px) {
      padding-left: 260px !important;
    }
  }
  .results-filter {
    z-index: 99;
  }
}

.collapsible-filters .view-filters .view-header {
  @include clearfix;

  .view-results {
    float: left;
  }
}

form ul.token-input-list {
  background-color: $platinum;
  border: none;
  color: $primary;
  width: 100%;
  border-radius: 40px;
  padding: 3px 15px 0;
  min-height: 35px;
  font: 1rem/1 $base-font-family;

  li.token-input-token {
    background: none;
    border-radius: 3px;
    color: $primary;
    cursor: default;
    display: block;
    font: 1rem/1 $base-font-family;
    margin: 3px 0px 3px 7px;
    max-width: 100%;
    padding: 5px 8px;
    position: relative;

    span {
      margin-left: 6px;
      color: $trolley_grey;
    }

    &.token-input-selected-token {
      background-color: $sea_blue;
      color: white;

      span {
        color: white;
      }

    }
  }

  input {
    margin: 0;
    padding: 0;
    background-color: transparent;
  }
}

div.token-input-dropdown {
  border: $primary-border;
  border-top: 0;
  box-shadow: $box-shadow;
  margin: 0 0 0 20px;
  //width: calc(100% - 10px);
  font: 1rem/1.1875 $base-font-family;

  p {
    margin: 0;
    padding: 5px;
    font-weight: bold;
    color: $primary;
  }


  ul {
    margin: 0;
    padding: 0;

    li {
      background-color: none;
      padding: 3px;
      list-style-type: none;

      &.token-input-dropdown-item {
        background-color: transparent;
      }

      &.token-input-dropdown-item2 {
        background-color: transparent;
      }

      &.token-input-selected-dropdown-item {
        background-color: $sea_blue;
        color: white;
      }

      em {
        font-weight: bold;
        font-style: normal;
      }
    }
  }
}

.ui-datepicker .ui-datepicker-title select {
  font-size: 0.7em;
}


// SPECIFIC FOR NEW HELP LIBRARY / SIDEBAR LAYOUT

.view-resources-search {
  display: flex;
  flex-direction: column;
  position: relative;
  @media(min-width: 780px) {
    flex-direction: row;
  }

  .filter-wrapper {
    @media(min-width: 780px) {
      //max-width: 50%;
      width: calc(30% - 20px);
      margin-right: 20px;
      //flex: 1;
      margin-top: 50px;
    }

    .results-filter {
      order: 6;
      width: 100%;
      @media(min-width: 780px) {
        position: absolute;
        right: 0;
        width: 100%;
        display: flex;
        .blank {
          width: 30%;
          margin-right: 20px;
        }
        .results-filter-inner {
          width: calc(100% - 210px);
          padding: 0 20px;
        }
      }
      @media(min-width: 1200px) {
        .results-filter-inner {
          width: calc(100% - 300px);
        }
      }
      @media(min-width: 1400px) {
        .results-filter-inner {
          width: calc(100% - 380px);
        }
      }

      .search-sort {
        width: calc(50% - 10px);
        margin-right: 20px;
      }


      .form-item label:not(.option) {
        padding: 0;
        background: none;
        margin: 0;
        font-size: 1em;
      }

      .form-item-sort-bef-combine {
        margin: 0 10px 0 0;
      }

      .views-widget-per-page {
        float: right;
        @media(min-width: 1110px) {
          margin-right: 110px;
        }

        label,
        select {
          display: inline;
        }

        select {
          display: inline;
        }
      }
    }

    #edit-secondary-wrapper {
      &.views-widget-filter-secondary {
        height: auto;
        margin-top: -30px;
      }

      .bef-secondary-options {
        display: flex;
        flex-direction: column;
      }

      .form-item {
        label:not(.option) {
          margin: 0 0 20px;
          background: #ECF1F4;
          padding: 20px;
        }

        &.form-type-select,
        &.form-type-radios {
          margin: 20px 0 0;
          background: white;
          padding: 20px;
          box-shadow: $box-shadow;
          float: none;
          width: 100% !important;
          //max-width: $filter-width;
          //@media(min-width: 720px) {
          //  max-width: 100%;
          //}
          .form-item {
            width: 100%;
            margin-bottom: 5px;
            word-break: break-word;

            &.sub-item {
              margin-left: 10px;
            }
          }

          .bef-checkboxes,
          .form-radios {
            height: 180px;
            overflow-y: auto;
            overflow-x: hidden;
          }

          select {
            max-width: calc(100% - 40px);
            margin: 20px;
          }
        }
      }
    }

    .container-inline-date {
      margin: 0 0 20px;
      background: white;
      box-shadow: $box-shadow;
      width: 100% !important;
      display: inline-block;

      .form-item {
        margin-bottom: 0;

        .form-item label {
          display: none;
        }
      }

      .form-type-date-popup input {
        max-width: calc(100% - 40px);
        margin: 0 20px 20px;
      }

      .description {
        display: none;
      }
    }

    .view-filters {
      position: static;

      .views-exposed-widgets {
        #edit-keywords-wrapper {
          .views-widget {
            .form-item {
              padding: 20px 0;

              input {
                width: 100%;
                margin-bottom: 0;
              }
            }
          }
        }

        .views-widget-filter-secondary {
          opacity: 1;
          margin: 0;
          padding: 0;
        }
      }

      .views-submit-button input[type="submit"],
      .views-reset-button input[type="submit"] {
        position: static;
        margin-top: -100px;
        margin-left: 20px;
      }

      .views-exposed-widgets {
        .expand-trigger {
          margin: 20px 0;
          padding: 20px;
        }

        #edit-keywords-wrapper {
          padding: 20px 20px 70px;
          background: white;
          width: 100%;
        }
      }
    }
  }

  .content-wrapper {
    margin-top: 30px;

    @media(min-width: 780px) {
      flex: 4;
    }

    .view-subtitle {
      display: none;
    }

    .view-header {
      margin-bottom: 30px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media(min-width: 780px) {
        margin-top: 110px;
      }
      @media(min-width: 960px) {
        margin-top: 70px;
      }
      @media(min-width: 1110px) {
        margin-top: 20px;
      }

      .layout-display {
        display: flex;

        .display-number {
          display: inline;
          margin-right: 10px;

          .form-item {
            margin: 0;
          }
        }

        .layout {
          display: inline;
        }
      }

      label {
        float: left;
        padding-top: 10px;
      }
    }
  }

  .form-item.form-type-select.form-item-int {
    display: none;
  }

  .view-empty {
    margin-top: 90px;
    background: white;
    padding: 30px;
    margin-right: 10px;
    font-size: 18px;
  }

}

.views-exposed-form .views-widget-filter-search_api_views_fulltext input.form-text {
  border: 2px solid #BACAD6;
  border-radius: 0;
}

#edit-search-api-views-fulltext-wrapper {
  background: white;
  padding: 20px 20px 60px;
}

label.option {
  font-weight: 400;
  font-size: 1em;
}

.bef-secondary-options {
  .collapsible {
    .fieldset-legend {
      background: none !important;

      .summary {
        background: $mango_tango $minus no-repeat scroll;
        background-size: 12px;
        background-position: 50%;
        height: 24px;
        width: 24px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%;
      }
    }
  }
}

fieldset#edit-secondary {
  margin-top: 30px;
  display: inline-block;
  width: 100%;
}

.view-resources-search .filter-wrapper #edit-secondary-wrapper .form-item.form-type-select {
  margin: 0 0 20px;
  padding: 0;

  fieldset.collapsible {
    legend {
      width: 100%;
      margin-bottom: 0;

      .fieldset-legend {
        padding: 0;

        a {
          background: $filter-collapse no-repeat 94% 50% / 24px 24px #ECF1F4;
          padding: 20px;
          width: 100%;
          display: inline-block;
          color: $primary;
          font-size: 20px;
          font-weight: 500;
        }

        .summary {
          display: none;
        }
      }
    }

    .fieldset-wrapper {
      padding: 20px;
    }

    &.collapsed {
      a {
        background: $filter-expand no-repeat 94% 50% / 24px 24px #ECF1F4 !important;
      }
    }
  }
}

.view-resources-search:not(.list) {
  .content-wrapper {
    .section-row:not(.view-type-slider).row-max-3 {
      .views-row {
        width: 100%;
        @media(min-width: 1100px) {
          width: calc(100% / 2 - 2.35765%);
        }
        @media(min-width: 1200px) {
          width: calc(100% / 3 - 2.35765%);
        }
      }
    }
  }
}

.d-none {
  display: none;
}

.loader {
  display: none;
  width: 100%;
  padding: 15px;
  height: 62px;
  background: url("../icons/favicon-32x32.png") no-repeat center;
  animation: spin 1s linear infinite;

  &.active {
    display: block;
  }
}

@keyframes spin{
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
}

li.views-row {
  list-style: none;
}

fieldset legend {
  display: none;
}
.fieldset-wrapper legend {
  display: inline-block;
}

.content-sort-wrapper {
  position:relative;
  width: 100%;
  .results-filter {
    position: absolute;
    top:0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    #edit-sort-bef-combine {
      width: 155px;
    }
    .form-item-items-per-page {
      @media(max-width: 1100px) {
        margin: 0;
      }
    }
  }
  .view-results {
    @media(min-width: 1110px) {
      padding-left:240px;
    }
  }
}

.feed-icon {
  display: none;
  width: 100%;
}

.fieldset-wrapper {
  max-width: 280px;
  @media(min-width: 414px) {
    max-width: 100%;
  }
}

@media(max-width: 720px) {
  .content-sort-wrapper {
    margin-bottom: 0;
  }
  .view-results {
    padding:0 10px!important;
    text-align: center;
    @media(min-width: 780px) {
    }
  }

}
@media(max-width: 400px) {
  .content-sort-wrapper {
    margin-bottom: 0;
  }
  .filter-results {
    flex-direction: column;
  }
  .search-sort {
    max-width: 225px;
  }
}

select#edit-items-per-page {
  max-width: 78px;
}
